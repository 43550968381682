import axios, { AxiosResponse } from 'axios'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'

import { AppState } from '@store/store'
import { axiosBaseQueryTyped, IError } from '@store/axiosBaseQueryTyped'
import {
  IGetFilmDetailsResponse,
  IMoviesFilmDetailsInitialState,
  TEpisode,
  TRelated,
} from '@assets/types/movies/filmDetails.types'
import { TLanguage } from '@assets/types/app.types'
import { IChannel } from '@assets/types/player.types'
import { dialogsActions } from '../dialogs.slice'

const initialState: IMoviesFilmDetailsInitialState = {
  episodes: {
    season: null,
    isActive: false,
    activeEpisodeIdx: 0,
  },
  related: {
    isActive: false,
    activeRelatedItemIdx: 0,
  },
  backBtn: false,
  playBtn: true,
  currentFilmId: '',
}

export const moviesFilmDetailsSlice = createSlice({
  name: 'moviesFilmDetails',
  initialState,
  reducers: {
    movies_filmDetails_reset: () => initialState,
    movies_filmDetails_setCurrentFilmId: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentFilmId = action.payload
    },
    movies_filmDetails_setBackBtnIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.backBtn = action.payload
    },
    movies_filmDetails_setPlayBtnIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.playBtn = action.payload
    },

    movies_filDetails_clickPlayBtn: (state) => {
      state.playBtn = true
      state.backBtn = false
      // Reset episodes section
      state.episodes.isActive = false
      state.episodes.activeEpisodeIdx = 0
      // Reset related section
      state.related.isActive = false
      state.related.activeRelatedItemIdx = 0
    },

    // Episodes section
    movies_filmDetails_episodes_setEpisodesIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.episodes.isActive = action.payload
    },
    movies_filmDetails_episodes_incrementActiveEpisodeIdx: (state) => {
      state.episodes.activeEpisodeIdx++
    },
    movies_filmDetails_episodes_decrementActiveEpisodeIdx: (state) => {
      state.episodes.activeEpisodeIdx--
    },
    movies_filmDetails_episodes_setActiveEpisodeIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.episodes.activeEpisodeIdx = action.payload
    },
    movies_filmDetails_episodes_click: (
      state,
      action: PayloadAction<number>
    ) => {
      state.episodes.isActive = true
      state.episodes.activeEpisodeIdx = action.payload
      state.playBtn = false
      state.backBtn = false
      // Reset related section
      state.related.isActive = false
      state.related.activeRelatedItemIdx = 0
    },
    movies_filmDetails_episodes_setSeason: (
      state,
      action: PayloadAction<string>
    ) => {
      state.episodes.season = action.payload
    },

    // Related section
    movies_filmDetails_related_setRelatedIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.related.isActive = action.payload
    },
    movies_filmDetails_related_incrementActiveRelatedItemIdx: (state) => {
      state.related.activeRelatedItemIdx++
    },
    movies_filmDetails_related_decrementActiveRelatedItemIdx: (state) => {
      state.related.activeRelatedItemIdx--
    },
    movies_filmDetails_related_setActiveRelatedItemIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.related.activeRelatedItemIdx = action.payload
    },
  },
})

export const moviesFilmDetailsApi = createApi({
  reducerPath: 'moviesFilmDetailsApi',
  baseQuery: axiosBaseQueryTyped({
    baseUrl: process.env.BASE_CDN_URL,
  }),

  endpoints: (builder) => ({
    get_movie_details: builder.query<
      IGetFilmDetailsResponse,
      { film_id: string; language: TLanguage }
    >({
      queryFn: async (_args, _api) => {
        const url = `${process.env.BASE_CDN_URL}/v1/catalog/${_args.film_id}/full_info.json&lang=${_args.language}`

        try {
          const { data } = await axios.get<IGetFilmDetailsResponse>(url)
          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_retry_setRetryDialogIsOpened(true)
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),

    get_episodes: builder.query<TEpisode[], { episodesUrl: string }>({
      queryFn: async (_args, _api) => {
        const getState = _api.getState as () => AppState
        try {
          const userChannels = getState().player.categories.items[0]
            .channels as IChannel[]
          const { data } = await axios.get<TEpisode[]>(_args.episodesUrl)

          // Filtering items by user's tariff
          const filteredEpisodes = data.filter((item) =>
            userChannels.some((channel) => channel.id === item.channel.id)
          )

          return { data: filteredEpisodes }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_retry_setRetryDialogIsOpened(true)
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),

    get_related_items: builder.query<
      TRelated[],
      { relatedUrl: string; currentMovieId: number }
    >({
      queryFn: async (_args, _api) => {
        const getState = _api.getState as () => AppState
        try {
          const userChannels = getState().player.categories.items[0]
            .channels as IChannel[]
          const { data } = await axios.get<TRelated[]>(_args.relatedUrl)
          // Filtering items by user's tariff
          const filteredRelatedItems = data.filter((item) =>
            userChannels.some(
              (channel) =>
                channel.id === item.channel.id &&
                item.id !== _args.currentMovieId
            )
          )

          return { data: filteredRelatedItems }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_retry_setRetryDialogIsOpened(true)
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),
  }),
})

export const moviesFilmDetailsReducer = moviesFilmDetailsSlice.reducer
export const moviesFilmDetailsActions = moviesFilmDetailsSlice.actions

export const {
  useGet_movie_detailsQuery,
  useGet_episodesQuery,
  useGet_related_itemsQuery,
} = moviesFilmDetailsApi
