import React, {
  ComponentProps,
  ElementType,
  memo,
  MouseEvent,
  ReactNode,
} from 'react'
import { clsx } from 'clsx'

import { mouseEnterElement } from '@utils/mouseEnterElement'
import { mouseLeaveElement } from '@utils/mouseLeaveElement'

import styles from '@components/UIElements/Button/button.module.scss'

type TButtonOwnProps<E extends ElementType = ElementType> = {
  children: ReactNode
  isActive: boolean
  className?: string
  wrapperClassName?: string
  as?: E
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>
}

type TButtonProps<E extends ElementType> = TButtonOwnProps<E> &
  Omit<ComponentProps<E>, keyof TButtonOwnProps>

const defaultElement = 'button'

const ButtonComponent = <E extends ElementType = typeof defaultElement>({
  children,
  as,
  className,
  wrapperClassName,
  isActive,
  onMouseEnter,
  onMouseLeave,
  ...props
}: TButtonProps<E>) => {
  const TagName = as || defaultElement

  return (
    <TagName
      className={clsx(
        styles.button,
        className && className,
        isActive && 'custom_element_background_gradient_uytsnh',
        isActive && styles.buttonActive
      )}
      {...props}
      onMouseDown={(event) => event.preventDefault()}
      onMouseEnter={
        onMouseEnter
          ? onMouseEnter
          : (event: MouseEvent<HTMLButtonElement>) =>
            mouseEnterElement(event.currentTarget, styles.buttonHover)
      }
      onMouseLeave={
        onMouseLeave
          ? onMouseLeave
          : (event: MouseEvent<HTMLButtonElement>) =>
            mouseLeaveElement(event.currentTarget, styles.buttonHover)
      }
    >
      <div
        className={clsx(
          styles.buttonText,
          wrapperClassName && wrapperClassName
        )}
      >
        {children}
      </div>
    </TagName>
  )
}

export const Button = memo(ButtonComponent) as typeof ButtonComponent
