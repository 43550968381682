import axios, { AxiosResponse } from 'axios'
import { createApi } from '@reduxjs/toolkit/query/react'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppState } from '@store/store'
import { axiosBaseQueryTyped, IError } from '@store/axiosBaseQueryTyped'
import { authActions } from '@store/slices/auth.slice'

import { kinozalDetailsActions } from '@store/slices/kinozal/kinozal.details'
import { dialogsActions } from '@store/slices/dialogs.slice'
import {
  IKinozalCommonInitialState,
  IAllKinozalResponse,
  IAllKinozalRequest,
  IKinozalSetClientApproveUnholdResponse,
  TCurrentRoute,
} from '@assets/types/kinozal/kinozal.common.types'
import { KinozalItemType } from '@assets/types/kinozal/kinozal.filmlist.types'
import {
  KINOZAL_RECENTLY_VIEWED_FILMS,
  KINOZAL_RECENTLY_VIEWED_EPISODES,
  KINOZAL_FAVORITES,
  KINOZAL_BLOCKED,
  BASE_KINOZAL_MAX_FILMS_NUMBER_IN_STORAGE,
  BASE_KINOZAL_MAX_SERIES_NUMBER_IN_STORAGE,
} from '@utils/vars'

const initialState: IKinozalCommonInitialState = {
  isKinozalMode: false,
  isShow: false,
  currentRoute: null,
  rightMenuIsActive: false,
  allContent: {
    isActive: true,
  },
  lastWatchedItem: {
    lastWatchedItemId: null,
    lastWatchedSeasonIdx: null,
    lastWatchedEpisodeIdx: null,
  },
  [KINOZAL_RECENTLY_VIEWED_FILMS]: [],
  [KINOZAL_RECENTLY_VIEWED_EPISODES]: [],
  [KINOZAL_FAVORITES]: [],
  [KINOZAL_BLOCKED]: [],
}

export const kinozalCommonSlice = createSlice({
  name: 'kinozalCommon',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(
      kinozalDetailsActions.kinozalDetails_setCurrentFilmId,
      (state) => {
        if (state.currentRoute !== 'filmDetails')
          state.currentRoute = 'filmDetails'
      }
    )
  },
  reducers: {
    kinozalCommon_resetKinozalMode: (state) => {
      state.isKinozalMode = false
      state.lastWatchedItem = initialState.lastWatchedItem
      state.isShow = false
      state.currentRoute = null
      state.rightMenuIsActive = false
      state.allContent = initialState.allContent
    },
    kinozalCommon_allContent_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.allContent.isActive = action.payload
    },
    kinozal_setCurrentRoute: (state, action: PayloadAction<TCurrentRoute>) => {
      state.currentRoute = action.payload
    },
    kinozalCommon_setIsKinozalMode: (state, action: PayloadAction<boolean>) => {
      state.isKinozalMode = action.payload
    },
    kinozalCommon_setIsShow: (state, action: PayloadAction<boolean>) => {
      state.isShow = action.payload
    },
    kinozalCommon_setRightMenuIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.rightMenuIsActive = action.payload
    },
    // last watched item
    kinozalCommon_setLastWatchedItem: (
      state,
      action: PayloadAction<{
        filmId: number
        currentSeasonIdx: number
        currentEpisodeIdx: number
      }>
    ) => {
      state.lastWatchedItem.lastWatchedItemId = action.payload.filmId
      state.lastWatchedItem.lastWatchedSeasonIdx =
        action.payload.currentSeasonIdx
      state.lastWatchedItem.lastWatchedEpisodeIdx =
        action.payload.currentEpisodeIdx
    },

    // persist
    kinozalCommon_setRecentlyViewedFilm: (
      state,
      action: PayloadAction<KinozalItemType>
    ) => {
      const isAlreadyViewed = !!state.kinozalRecentlyViewedFilms?.find(
        (film) => film.id === action.payload.id
      )
      if (!isAlreadyViewed) {
        if (
          state.kinozalRecentlyViewedFilms.length ===
          BASE_KINOZAL_MAX_FILMS_NUMBER_IN_STORAGE
        ) {
          state.kinozalRecentlyViewedFilms.shift()
        }
        state.kinozalRecentlyViewedFilms.push(action.payload)
      }
    },
    kinozalCommon_setRecentlyViewedEpisode: (
      state,
      action: PayloadAction<{ id: number }>
    ) => {
      const isAlreadyViewed = state.kinozalRecentlyViewedEpisodes?.find(
        (episode) => episode.id === action.payload.id
      )
      if (!isAlreadyViewed) {
        if (
          state.kinozalRecentlyViewedEpisodes.length ===
          BASE_KINOZAL_MAX_SERIES_NUMBER_IN_STORAGE
        ) {
          state.kinozalRecentlyViewedEpisodes.shift()
        }
        state.kinozalRecentlyViewedEpisodes.push(action.payload)
      }
    },
    kinozalCommon_setFavoriteFilm: (
      state,
      action: PayloadAction<KinozalItemType>
    ) => {
      const isAlreadyFavorite = state.kinozalFavorites.find(
        (film) => film.id === action.payload.id
      )

      if (isAlreadyFavorite) {
        state.kinozalFavorites = state.kinozalFavorites.filter(
          (film) => film.id !== action.payload.id
        )
      } else {
        if (
          state.kinozalFavorites.length ===
          BASE_KINOZAL_MAX_FILMS_NUMBER_IN_STORAGE
        ) {
          state.kinozalFavorites.shift()
        }
        state.kinozalFavorites.push(action.payload)
      }
    },
    kinozalCommon_recentlyViewedFilm_setAudioTrackId: (
      state,
      action: PayloadAction<{
        filmid: number
        audioTrackId: number
      }>
    ) => {
      const film = state.kinozalRecentlyViewedFilms.find(
        (film) => film.id === action.payload.filmid
      )
      if (film) film.audioTrackId = action.payload.audioTrackId
    },
  },
})

export const kinozalCommonApi = createApi({
  reducerPath: 'kinozalCommonApi',
  baseQuery: axiosBaseQueryTyped({
    baseUrl: '',
  }),
  endpoints: (builder) => ({
    // ${baseUrl}/${playlist}/filters/all.json?lang=${language}
    get_all_filters: builder.query<IAllKinozalResponse, IAllKinozalRequest>({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState
        const baseUrl = appState().auth.authSuccessData?.archive_megogo
          .base_url as string
        const playlist = appState().auth.authSuccessData?.playlist as string

        const url = `${baseUrl}/${playlist}/filters/all.json?lang=${_args.language}`

        try {
          const { data } = await axios.get<IAllKinozalResponse>(url)

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_retry_setRetryDialogIsOpened(true)
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),

    // https://cdnua06.hls.tv/v1/archive_megogo/client/{playlistToken}/approve
    set_client_approve: builder.query<IKinozalSetClientApproveUnholdResponse, void>({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState
        const playlist = appState().auth.authSuccessData?.playlist as string
        const baseUrl = appState().auth.authSuccessData?.archive_megogo.base_url as string
        const newBaseUrl = baseUrl.replace(/\/\d+$/, '')
        const url = `${newBaseUrl}/client/${playlist}/approve`
        try {
          const { data } =
            await axios.get<IKinozalSetClientApproveUnholdResponse>(url)
          if (data.status === 'ok' && data.result?.action === 'approved') {
            _api.dispatch(
              authActions.set_authSuccessData_archive_megogo_approved()
            )
          }

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError

          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
      keepUnusedDataFor: 0,
    }),

    // https://cdnua06.hls.tv/v1/archive_megogo/client/{playlist}/unhold
    set_client_unhold: builder.query<IKinozalSetClientApproveUnholdResponse, void>({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState
        const playlist = appState().auth.authSuccessData?.playlist as string
        const baseUrl = appState().auth.authSuccessData?.archive_megogo.base_url as string
        const newBaseUrl = baseUrl.replace(/\/\d+$/, '')
        const url = `${newBaseUrl}/client/${playlist}/unhold`

        try {
          const { data } =
            await axios.get<IKinozalSetClientApproveUnholdResponse>(url)
          if (data.status === 'ok' && data.result?.action === 'unhold') {
            _api.dispatch(
              authActions.set_authSuccessData_archive_megogo_unhold()
            )
          }

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError

          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
      keepUnusedDataFor: 0,
    }),
  }),
})

export const kinozalCommonReducer = kinozalCommonSlice.reducer
export const kinozalCommonActions = kinozalCommonSlice.actions

export const {
  useGet_all_filtersQuery,
  useSet_client_approveQuery,
  useLazySet_client_unholdQuery,
} = kinozalCommonApi
