import { useLayoutEffect, useState } from 'react'

import { useTypedAction } from '@hooks/common/useTypedActions'
import { useLoadLanguage } from '@hooks/app/initialSettings/useLoadLanguage'

import { getQueryValueFromString } from '@utils/getQueryValueFromString'
import { BILINK_ID, ITTV_ID, MAXIMUM_ID } from '@utils/vars'

export const useLoadInitialAppSettings = (): boolean => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const { app_setCustomizationCompanyId } = useTypedAction()

  const companyId = getQueryValueFromString(window.location.search, 'company_id')

  const loadLanguage = useLoadLanguage()

  useLayoutEffect(() => {
    loadLanguage()
    switch (companyId) {
    // Maximum
    case MAXIMUM_ID: {
      import(
        /* webpackChunkName: '133'*/ '../../../styles/styles.scss?133'
      ).then(() => {
        if (companyId) app_setCustomizationCompanyId(companyId)
        setIsLoaded(true)
      })
      break
    }

    // Bilink
    case BILINK_ID: {
      import(
        /* webpackChunkName: '626'*/ '../../../styles/styles.scss?626'
      ).then(() => {
        if (companyId) app_setCustomizationCompanyId(companyId)
        setIsLoaded(true)
      })
      break
    }

    // ItTv
    case ITTV_ID: {
      import(
        /* webpackChunkName: '512'*/ '../../../styles/styles.scss?512'
      ).then(() => {
        if (companyId) app_setCustomizationCompanyId(companyId)
        setIsLoaded(true)
      })
      break
    }
    default:
      setIsLoaded(true)
      break
    }
  }, [])

  return isLoaded
}
