import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import axios, { AxiosResponse } from 'axios'
import { axiosBaseQueryTyped, IError } from '@store/axiosBaseQueryTyped'

import { AppState } from '@store/store'
import { kinozalCommonApi, kinozalCommonActions } from '@store/slices/kinozal/kinozal.common.slice'

import {
  IKinozalDetailsInitialState,
  IKinozalDetailsResponse,
  IKinozalDetailsRequest,
  IKinozalDetailsActorInfoResponse,
  IKinozalDetailsActorInfoRequest,
  IKinozalSeasonInfoResponse,
  IKinozalSeasonInfoRequest,
  IKinozalPlaylistByIDResponse,
  IKinozalPlaylistByIDRequest,
  TKinozalDetailsTransformResponse,
  TKinozalDetailsActorInfoTransformResponse,
} from '@assets/types/kinozal/kinozal.details.types'
import { dialogsActions } from '../dialogs.slice'

const initialState: IKinozalDetailsInitialState = {
  backBtn: false,
  playBtn: true,
  trailerBtn: false,
  favoritesBtn: false,
  currentFilmId: '',
  isTrailer: false,
  currentPlayVideoId: null,
  actors: {
    isActive: false,
    activeIdx: 0,
    actor: {
      backBtn: true,
      filmography: {
        isActive: false,
        activeIdx: 0,
      },
      description: {
        isActive: false,
        showMoreBtn: false,
        isShow: false,
      },
    },
  },
  seasons: {
    isActive: false,
    selectedIdx: 0,
    activeIdx: 0,
  },
  espisodes: {
    isActive: false,
    activeIdx: 0,
  },
  recommend: {
    isActive: false,
    activeIdx: 0,
  },
}

export const kinozalDetailsSlice = createSlice({
  name: 'kinozalDetails',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(kinozalCommonActions.kinozalCommon_resetKinozalMode, () => initialState)
  },
  reducers: {
    kinozalDetails_partialInitialState: (state) => ({
      ...initialState,
      currentFilmId: state.currentFilmId,
      currentPlayVideoId: state.currentPlayVideoId,
    }),
    kinozalDetails_initialState: (
      state,
      action: PayloadAction<undefined | boolean>
    ) => ({
      ...initialState,
      currentPlayVideoId: action.payload ? null : state.currentPlayVideoId,
    }),

    kinozalDetails_removeActiveState: (state) => {
      state.backBtn = false
      state.playBtn = false
      state.trailerBtn = false
      state.favoritesBtn = false
      state.seasons.isActive = false
      state.espisodes.isActive = false
      state.actors.isActive = false
      state.recommend.isActive = false
    },
    kinozalDetails_setCurrentFilmId: (state, action: PayloadAction<string>) => {
      state.currentFilmId = action.payload
    },
    kinozalDetails_setCurrentPlayVideoId: (
      state,
      action: PayloadAction<{
        videoId: number | null
        setEpisodeIdx?: 'next' | 'prev'
      }>
    ) => {
      state.currentPlayVideoId = action.payload.videoId
    },
    // backBtn
    kinozalDetails_backBtn_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.backBtn = action.payload
    },
    // playBtn
    kinozalDetails_playBtn_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.playBtn = action.payload
    },
    kinozalDetails_playBtn_click: (state) => {
      state.playBtn = true
      state.trailerBtn = false
      state.favoritesBtn = false
      state.seasons.isActive = false
      state.espisodes.isActive = false
      state.backBtn = false
      state.actors.isActive = false
      state.recommend.isActive = false
    },
    // trailerBtn
    kinozalDetails_trailerBtn_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.trailerBtn = action.payload
    },
    kinozalDetails_trailerBtn_click: (state) => {
      state.trailerBtn = true
      state.favoritesBtn = false
      state.seasons.isActive = false
      state.espisodes.isActive = false
      state.backBtn = false
      state.playBtn = false
      state.actors.isActive = false
      state.recommend.isActive = false
    },
    // favoritesBtn
    kinozalDetails_favoritesBtn_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.favoritesBtn = action.payload
    },
    kinozalDetails_favoritesBtn_click: (state) => {
      state.favoritesBtn = true
      state.seasons.isActive = false
      state.espisodes.isActive = false
      state.backBtn = false
      state.playBtn = false
      state.trailerBtn = false
      state.actors.isActive = false
      state.recommend.isActive = false
    },
    // isTrailer
    kinozalDetails_setIsTrailer: (state, action: PayloadAction<boolean>) => {
      state.isTrailer = action.payload
    },
    // seasons
    kinozalDetails_seasons_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.seasons.isActive = action.payload
    },
    kinozalDetails_seasons_setSelectedIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.seasons.selectedIdx = action.payload
    },
    kinozalDetails_seasons_setActiveIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.seasons.activeIdx = action.payload
    },
    kinozalDetails_seasons_incrementActiveIdx: (state) => {
      state.seasons.activeIdx++
    },
    kinozalDetails_seasons_decrementActiveIdx: (state) => {
      state.seasons.activeIdx--
    },
    kinozalDetails_seasons_click: (state, action: PayloadAction<number>) => {
      state.seasons.isActive = true
      state.seasons.activeIdx = action.payload
      state.seasons.selectedIdx = action.payload
      state.espisodes.isActive = false
      state.espisodes.activeIdx = 0
      state.backBtn = false
      state.playBtn = false
      state.favoritesBtn = false
      state.trailerBtn = false
      state.actors.isActive = false
      state.recommend.isActive = false
    },
    // episodes
    kinozalDetails_episodes_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.espisodes.isActive = action.payload
    },
    kinozalDetails_episodes_incrementActiveIdx: (state) => {
      state.espisodes.activeIdx++
    },
    kinozalDetails_episodes_decrementActiveIdx: (state) => {
      state.espisodes.activeIdx--
    },
    kinozalDetails_episodes_setActiveIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.espisodes.activeIdx = action.payload
    },
    kinozalDetails_episodes_click: (state, action: PayloadAction<number>) => {
      state.espisodes.isActive = true
      state.espisodes.activeIdx = action.payload
      state.backBtn = false
      state.playBtn = false
      state.favoritesBtn = false
      state.trailerBtn = false
      state.seasons.isActive = false
      state.actors.isActive = false
      state.recommend.isActive = false
    },

    // actors
    kinozalDetails_actors_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.actors.isActive = action.payload
    },
    kinozalDetails_actors_incrementActiveIdx: (state) => {
      state.actors.activeIdx++
    },
    kinozalDetails_actors_decrementActiveIdx: (state) => {
      state.actors.activeIdx--
    },
    // actor
    kinozalDetails_actors_actor_backBtn_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.actors.actor.backBtn = action.payload
    },
    kinozalDetails_actors_actor_filmography_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.actors.actor.filmography.isActive = action.payload
    },
    kinozalDetails_actors_actor_filmography_incrementActiveIdx: (state) => {
      state.actors.actor.filmography.activeIdx++
    },
    kinozalDetails_actors_actor_filmography_decrementActiveIdx: (state) => {
      state.actors.actor.filmography.activeIdx--
    },
    kinozalDetails_actors_actor_description_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.actors.actor.description.isActive = action.payload
    },
    kinozalDetails_actors_actor_description_setShowMoreBtn: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.actors.actor.description.showMoreBtn = action.payload
    },
    kinozalDetails_actors_actor_description_setIsShow: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.actors.actor.description.isShow = action.payload
    },
    // recommend
    kinozalDetails_recommend_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.recommend.isActive = action.payload
    },
    kinozalDetails_recommend_incrementActiveIdx: (state) => {
      state.recommend.activeIdx++
    },
    kinozalDetails_recommend_decrementActiveIdx: (state) => {
      state.recommend.activeIdx--
    },
  },
})

export const kinozalDetailsApi = createApi({
  reducerPath: 'kinozalDetailsApi',
  baseQuery: axiosBaseQueryTyped({
    baseUrl: '',
  }),
  endpoints: (builder) => ({
    // https://{base_url}/{playlist}/video/{video_Id}/info.json?lang=en
    get_details_by_film_id: builder.query<
      TKinozalDetailsTransformResponse,
      IKinozalDetailsRequest
    >({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState
        const baseUrl = appState().auth.authSuccessData?.archive_megogo
          .base_url as string
        const playlist = appState().auth.authSuccessData?.playlist as string

        const url = `${baseUrl}/${playlist}/video/${_args.filmId}/info.json?lang=${_args.language}`
        try {
          const { data } = await axios.get<IKinozalDetailsResponse>(url)
          if (Array.isArray(data.result)) {
            return { data: { status: 'ok', result: undefined } }
          }
          return { data: { status: data.status, result: data?.result } }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_retry_setRetryDialogIsOpened(true)
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),
    //  https://{base_url}/{playlist}/people/{people_id}/info.json?lang=en
    get_details_by_actor_id: builder.query<
      TKinozalDetailsActorInfoTransformResponse,
      IKinozalDetailsActorInfoRequest
    >({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState
        const baseUrl = appState().auth.authSuccessData?.archive_megogo
          .base_url as string
        const playlist = appState().auth.authSuccessData?.playlist as string

        const url = `${baseUrl}/${playlist}/people/${_args.actorId}/info.json?lang=${_args.language}`

        try {
          const { data } = await axios.get<IKinozalDetailsActorInfoResponse>(
            url
          )
          if (Array.isArray(data.result)) {
            return { data: { status: 'ok', result: undefined } }
          }
          return { data: { status: data.status, result: data?.result } }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),
    // https://{base_url}/{playlist}/video/season/{seasonId}/list.json?lang=en
    get_season_by_id: builder.query<
      IKinozalSeasonInfoResponse,
      IKinozalSeasonInfoRequest
    >({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState
        const baseUrl = appState().auth.authSuccessData?.archive_megogo
          .base_url as string
        const playlist = appState().auth.authSuccessData?.playlist as string

        const url = `${baseUrl}/${playlist}/video/season/${_args.seasonId}/list.json?lang=${_args.language}`

        try {
          const { data } = await axios.get<IKinozalSeasonInfoResponse>(url)
          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),
    // https://{base_url}/{playlist}/video/{videoId}/stream.json
    get_kinozal_playlist_by_id: builder.query<
      IKinozalPlaylistByIDResponse,
      IKinozalPlaylistByIDRequest
    >({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState
        const baseUrl = appState().auth.authSuccessData?.archive_megogo
          .base_url as string
        const playlist = appState().auth.authSuccessData?.playlist as string
        const archiveMegogo = appState().auth.authSuccessData?.archive_megogo

        const url = `${baseUrl}/${playlist}/video/${_args.videoId}/stream.json?lang=${_args.language}`

        if (archiveMegogo?.approved === 0) {
          const { data } = await _api.dispatch(
            kinozalCommonApi.endpoints.set_client_approve.initiate(undefined, {
              forceRefetch: true,
            })
          )
          if (data && data.status !== 'ok') {
            _api.dispatch(
              dialogsActions.dialogs_error_setDescription(`${data.message}`)
            )
            _api.dispatch(
              dialogsActions.dialogs_error_setErrorCode(`${data.code}`)
            )

            return {
              error: {
                status: 200,
                statusText: data.status,
              },
            }
          }
        }
        if (archiveMegogo?.approved === 1 && archiveMegogo.hold === 1) {
          const { data } = await _api.dispatch(
            kinozalCommonApi.endpoints.set_client_unhold.initiate(undefined, {
              forceRefetch: true,
            })
          )
          if (data && data.status !== 'ok') {
            _api.dispatch(
              dialogsActions.dialogs_error_setDescription(`${data.message}`)
            )
            _api.dispatch(
              dialogsActions.dialogs_error_setErrorCode(`${data.code}`)
            )
            return {
            
              error: {
                status: 200,
                statusText: data.status,
              },
            }
          }
        }

        try {
          const { data } = await axios.get<IKinozalPlaylistByIDResponse>(url)

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),
  }),
})

export const kinozalDetailsReducer = kinozalDetailsSlice.reducer
export const kinozalDetailsActions = kinozalDetailsSlice.actions
export const {
  useGet_details_by_film_idQuery,
  useGet_details_by_actor_idQuery,
  useGet_season_by_idQuery,
  useLazyGet_season_by_idQuery,
  useLazyGet_kinozal_playlist_by_idQuery,
} = kinozalDetailsApi
