import React, { FC, Suspense } from 'react'

import { useTypedSelector } from '@hooks/common/useTypedSelector'

import { lazyImport } from '@utils/lazyImport'

const { ConfirmExitKinozal } = lazyImport(
  () =>
    import(
      /*webpackChunkName: 'confirm-exit-kinozal'*/ '@components/Dialogs/ConfirmExitKinozal/ConfirmExitKinozal'
    ),
  'ConfirmExitKinozal'
)

export const LazyConfirmExitKinozal: FC = () => {
  const isOpened = useTypedSelector(
    (state) => state.dialogs.confirm.exitKinozal.isOpened
  )

  return <Suspense fallback={null}>{isOpened && <ConfirmExitKinozal />}</Suspense>
}
