import React, { FC, PropsWithChildren, Suspense } from 'react'

import { useAuth } from '@hooks/auth/useAuth'

import { lazyImport } from '@utils/lazyImport'

import { FullScreenLoader } from '@components/Overlays/FullScreenLoader/FullScreenLoader'

const { B2cAuth } = lazyImport(
  () => import(/* webpackChunkName: 'b2c-auth'*/ '@components/B2cAuth/B2cAuth'),
  'B2cAuth'
)
const { B2bAuth } = lazyImport(
  () => import(/* webpackChunkName: 'b2b-auth'*/ '@components/B2bAuth/B2bAuth'),
  'B2bAuth'
)
const { SuspendScreen } = lazyImport(
  () =>
    import(
      /* webpackChunkName: 'suspend'*/ '@components/SuspendScreen/SuspendScreen'
    ),
  'SuspendScreen'
)

export const Initial: FC<PropsWithChildren> = ({ children }) => {
  const { data, isLoading, isAuthenticated } = useAuth()

  if (isLoading) return <FullScreenLoader loaderSize={3} />

  if (isAuthenticated) return <>{children}</>

  // open suspend screen if b2c user was blocked
  if (
    data &&
    typeof data.success === 'object' &&
    data.success?.message.b2c &&
    data.success.trashed?.type === 'paused'
  ) {
    return (
      <Suspense fallback={<FullScreenLoader loaderSize={3} />}>
        <SuspendScreen />
      </Suspense>
    )
  }

  if (
    data &&
    (data.error === 17 || (data.error === 1 && data.trashed?.type === 'paused'))
  ) {
    return (
      <Suspense fallback={<FullScreenLoader loaderSize={3} />}>
        <SuspendScreen />
      </Suspense>
    )
  }

  if (
    typeof data?.success === 'object' &&
    data?.success?.message.b2c &&
    data?.success?.message.new
  )
    return (
      <Suspense fallback={<FullScreenLoader loaderSize={3} />}>
        <B2cAuth />
      </Suspense>
    )

  if (
    typeof data?.success === 'object' &&
    !data?.success?.message.b2c &&
    data?.success?.trashed
  )
    return (
      <Suspense fallback={<FullScreenLoader loaderSize={3} />}>
        <B2bAuth />
      </Suspense>
    )

  return null
}
