import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import FormData from 'form-data'
import axios, { AxiosResponse } from 'axios'
import { t } from 'i18next'

import { axiosBaseQueryTyped, IError } from '@store/axiosBaseQueryTyped'
import { dialogsActions } from '@store/slices/dialogs.slice'
import { authApi } from '@store/slices/auth.slice'
import { appRightMenuActions } from '@store/slices/app.right.menu.slice'
import { playerActions } from '@store/slices/player.slice'

import {
  IActivateVoucherRequest,
  IActivateVoucherResponse,
  IGetPaymentRequest,
  IOverlaysInitialState,
  IGetParamsForLiqPayQrResponse,
  TCheckPasswordOverlayType,
  TError,
  IGetPrivacyPolicyRequest,
  IGetPrivacyPolicyResponse,
  IGetPlatonPayCredentialsResponse,
  ICheckPaymentResponse,
  ICheckPaymentRequest,
  IGetLiqPayQrTransformResponse,
} from '@assets/types/overlays.types'
import { IGenerateQrResponse } from '@assets/types/app.types'

import { USER_TOKEN } from '@utils/vars'

const initialState: IOverlaysInitialState = {
  hlsError: {
    isOpened: false,
    message: null,
    isNetworkError: false,
  },
  payment: {
    isOpened: false,
    amount: null,
    planName: null,
    backBtnIsActive: false,
    privacyPolicyIsActive: false,
    platonPayIsActive: false,
    platonPayIsLoaded: false,
  },
  privacyPolicy: {
    isOpened: false,
  },
  checkPassword: {
    isOpened: false,
    inputIsActive: true,
    submitIsActive: false,
    cancelIsActive: false,
    value: '',
    error: null,
    type: null,
  },
  setPassword: {
    isOpened: false,
    value: '',
    confirmValue: '',
    error: null,
    confirmError: null,
    inputIsActive: true,
    confirmInputIsActive: false,
    submitIsActive: false,
    cancelIsActive: false,
  },
  editDevice: {
    isOpened: false,
    value: '',
    uniq: '',
    inputIsActive: true,
    submitIsActive: false,
    cancelIsActive: false,
  },
  activateVoucher: {
    isOpened: false,
    error: null,
    value: '',
    inputIsActive: true,
    submitIsActive: false,
    cancelIsActive: false,
  },
  deviceManager: {
    isOpened: false,
    changeAccountIsActive: false,
    isInvalidateUserData: false,
    deviceList: {
      isActive: true,
      activeDeviceIdx: 0,
    },
  },
  choosePlan: {
    isOpened: false,
    freeChannelsIsActive: false,
    planList: {
      isActive: true,
      activePlanIdx: 0,
    },
  },
}

export const overlaysSlice = createSlice({
  name: 'overlays',
  initialState,
  reducers: {
    // hls errors
    overlays_hlsError_resetState: (state) => {
      state.hlsError = initialState.hlsError
    },
    overlays_hlsError_setIsOpened: (
      state,
      action: PayloadAction<{
        isNetworkError: boolean
        message: string
        isOpened: boolean
      }>
    ) => {
      state.hlsError.isNetworkError = action.payload.isNetworkError
      state.hlsError.message = action.payload.message
      state.hlsError.isOpened = action.payload.isOpened
    },

    // check password
    overlays_checkPassword_reset: (state) => {
      state.checkPassword = initialState.checkPassword
    },
    overlays_checkPassword_setIsOpened: (
      state,
      action: PayloadAction<{ isOpened: true; type: TCheckPasswordOverlayType }>
    ) => {
      state.checkPassword.type = action.payload.type
      state.checkPassword.isOpened = action.payload.isOpened
    },
    overlays_checkPassword_setValue: (state, action: PayloadAction<string>) => {
      state.checkPassword.value = action.payload
    },

    overlays_checkPassword_setInputIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.checkPassword.inputIsActive = action.payload
      state.checkPassword.submitIsActive = false
      state.checkPassword.cancelIsActive = false
    },
    overlays_checkPassword_setSubmitIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.checkPassword.submitIsActive = action.payload
      state.checkPassword.inputIsActive = false
      state.checkPassword.cancelIsActive = false
    },
    overlays_checkPassword_setCancelIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.checkPassword.cancelIsActive = action.payload
    },
    overlays_checkPassword_setError: (state, action: PayloadAction<TError>) => {
      state.checkPassword.error = action.payload
    },

    // set password
    overlays_setPassword_reset: (state) => {
      state.setPassword = initialState.setPassword
    },
    overlays_setPassword_setIsOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.setPassword.isOpened = action.payload
    },
    overlays_setPassword_setValue: (state, action: PayloadAction<string>) => {
      state.setPassword.value = action.payload
    },
    overlays_setPassword_setConfirmValue: (
      state,
      action: PayloadAction<string>
    ) => {
      state.setPassword.confirmValue = action.payload
    },
    overlays_setPassword_setError: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.setPassword.error = action.payload
    },
    overlays_setPassword_setConfirmError: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.setPassword.confirmError = action.payload
    },
    overlays_setPassword_setInputIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.setPassword.inputIsActive = action.payload
      state.setPassword.confirmInputIsActive = false
      state.setPassword.submitIsActive = false
      state.setPassword.cancelIsActive = false
    },
    overlays_setPassword_setConfirmInputIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.setPassword.confirmInputIsActive = action.payload
      state.setPassword.inputIsActive = false
      state.setPassword.submitIsActive = false
      state.setPassword.cancelIsActive = false
    },
    overlays_setPassword_setSubmitIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.setPassword.submitIsActive = action.payload
      state.setPassword.confirmInputIsActive = false
      state.setPassword.inputIsActive = false
      state.setPassword.cancelIsActive = false
    },
    overlays_setPassword_setCancelIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.setPassword.cancelIsActive = action.payload
    },

    // edit device
    overlays_editDevice_reset: (state) => {
      state.editDevice = initialState.editDevice
    },
    overlays_editDevice_setIsOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.editDevice.isOpened = action.payload
    },
    overlays_editDevice_setValue: (state, action: PayloadAction<string>) => {
      state.editDevice.value = action.payload
    },
    overlays_editDevice_setUniq: (state, action: PayloadAction<string>) => {
      state.editDevice.uniq = action.payload
    },
    overlays_editDevice_setInputIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.editDevice.inputIsActive = action.payload
      state.editDevice.submitIsActive = false
      state.editDevice.cancelIsActive = false
    },
    overlays_editDevice_setSubmitIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.editDevice.submitIsActive = action.payload
      state.editDevice.inputIsActive = false
      state.editDevice.cancelIsActive = false
    },
    overlays_editDevice_setCancelIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.editDevice.cancelIsActive = action.payload
    },

    // voucher
    overlays_activateVoucher_reset: (state) => {
      state.activateVoucher = initialState.activateVoucher
    },
    overlays_activateVoucher_setIsOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.activateVoucher.isOpened = action.payload
    },
    overlays_activateVoucher_setValue: (
      state,
      action: PayloadAction<string>
    ) => {
      state.activateVoucher.value = action.payload
    },
    overlays_activateVoucher_setError: (
      state,
      action: PayloadAction<TError>
    ) => {
      state.activateVoucher.error = action.payload
    },
    overlays_activateVoucher_setInputIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.activateVoucher.inputIsActive = action.payload
      state.activateVoucher.submitIsActive = false
      state.activateVoucher.cancelIsActive = false
    },
    overlays_activateVoucher_setSubmitIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.activateVoucher.submitIsActive = action.payload
      state.activateVoucher.inputIsActive = false
      state.activateVoucher.cancelIsActive = false
    },
    overlays_activateVoucher_setCancelIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.activateVoucher.cancelIsActive = action.payload
    },

    // privacy-policy
    overlays_privacyPolicy_setIsOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.privacyPolicy.isOpened = action.payload
    },

    // payment
    overlays_payment_reset: (state) => {
      state.payment = initialState.payment
    },
    overlays_payment_setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.payment.isOpened = action.payload
    },
    overlays_payment_setPlanData: (
      state,
      action: PayloadAction<{ planName: string | null; planPrice: string }>
    ) => {
      state.payment.amount = action.payload.planPrice
      state.payment.planName = action.payload.planName
    },
    overlays_payment_setBackBtnIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.payment.backBtnIsActive = action.payload
    },
    overlays_payment_clickBackBtn: (state) => {
      state.payment.backBtnIsActive = true
      state.payment.privacyPolicyIsActive = false
      state.payment.platonPayIsActive = false
    },
    overlays_payment_setPrivacyPolicyIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.payment.privacyPolicyIsActive = action.payload
    },
    overlays_payment_clickPrivacyPolicy: (state) => {
      state.payment.privacyPolicyIsActive = true
      state.payment.backBtnIsActive = false
      state.payment.platonPayIsActive = false
    },
    overlays_payment_setPlatonPayIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.payment.platonPayIsActive = action.payload
    },
    overlays_payment_setPlatonPayIsLoaded: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.payment.platonPayIsLoaded = action.payload
    },

    // device manager
    overlays_deviceManager_reset: (state) => {
      state.deviceManager = initialState.deviceManager
    },
    overlays_deviceManager_setisOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.deviceManager.isOpened = action.payload
    },
    overlays_deviceManager_setIsInvalidateUserData: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.deviceManager.isInvalidateUserData = action.payload
    },
    overlays_deviceManager_setChangeAccountIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.deviceManager.changeAccountIsActive = action.payload
    },
    overlays_deviceManager_deviceList_setDeviceListIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.deviceManager.deviceList.isActive = action.payload
    },
    overlays_deviceManager_deviceList_incrementActiveDeviceIdx: (state) => {
      state.deviceManager.deviceList.activeDeviceIdx++
    },
    overlays_deviceManager_deviceList_decrementActiveDeviceIdx: (state) => {
      state.deviceManager.deviceList.activeDeviceIdx--
    },
    overlays_deviceManager_deviceList_setActiveDeviceIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.deviceManager.deviceList.activeDeviceIdx = action.payload
    },

    // choose plan
    overlays_choosePlan_reset: (state) => {
      state.choosePlan = initialState.choosePlan
    },
    overlays_choosePlan_setIsOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.choosePlan.isOpened = action.payload
    },
    overlays_choosePlan_setFreeChannelsIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.choosePlan.freeChannelsIsActive = action.payload
    },
    overlays_choosePlan_planList_setPlanListIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.choosePlan.planList.isActive = action.payload
    },
    overlays_choosePlan_planList_incrementActivePlanIdx: (state) => {
      state.choosePlan.planList.activePlanIdx++
    },
    overlays_choosePlan_planList_decrementActivePlanIdx: (state) => {
      state.choosePlan.planList.activePlanIdx--
    },
  },
})

export const overlaysApi = createApi({
  reducerPath: 'overlaysApi',
  baseQuery: axiosBaseQueryTyped({
    baseUrl: process.env.BASE_CDN_URL,
  }),
  endpoints: (builder) => ({
    // https://api.omegatv.ua/voucher
    activate_voucher: builder.mutation<
      IActivateVoucherResponse,
      IActivateVoucherRequest
    >({
      queryFn: async (_args, _api) => {
        const token = localStorage.getItem(USER_TOKEN)
        const url = `${process.env.BASE_API_URL}/voucher`

        const formData = new FormData()
        formData.append('token', token)
        formData.append('card_code', _args.card_code)

        try {
          const { data } = await axios.post<IActivateVoucherResponse>(
            url,
            formData
          )

          if (data && data.code !== '200') {
            _api.dispatch(dialogsActions.dialogs_error_setErrorCode(data.code))
            return { data }
          }

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_error_setDescription(
              statusText ? statusText : null
            )
          )
          _api.dispatch(
            dialogsActions.dialogs_error_setErrorCode(
              status ? status.toString() : '500'
            )
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),

    // https://api.omegatv.ua/pay/liqpay/pay_service_qr_v
    get_liqpay_qr: builder.mutation<
      IGetLiqPayQrTransformResponse,
      IGetPaymentRequest
    >({
      queryFn: async (_args, _api) => {
        const liqpayCredentialsUrl = `${process.env.BASE_API_URL}/pay/liqpay/pay_service_qr_v2`
        const token = localStorage.getItem(USER_TOKEN)

        const formData = new FormData()
        formData.append('amount', _args.amount)
        formData.append('token', token)

        try {
          const { data: liqpayCredentials } =
            await axios.post<IGetParamsForLiqPayQrResponse>(
              liqpayCredentialsUrl,
              formData
            )

          if (liqpayCredentials.code !== '200') {
            _api.dispatch(
              dialogsActions.dialogs_error_setErrorCode(liqpayCredentials.code)
            )

            return {
              error: {
                status: +liqpayCredentials.code,
                statusText: undefined,
              },
            }
          }

          const getQrUrl = `${process.env.BASE_REACT_PLAYER_API}/api/get-liqpay-qr`

          try {
            const { data } = await axios.post<IGenerateQrResponse>(getQrUrl, {
              data: liqpayCredentials.response?.param_post.data,
              signature: liqpayCredentials.response?.param_post.signature,
            })

            if (data && data.code !== '200') {
              _api.dispatch(
                dialogsActions.dialogs_error_setErrorCode(data.code)
              )

              return {
                error: {
                  status: +data.code,
                  statusText: undefined,
                },
              }
            }

            return {
              data: {
                order_id: liqpayCredentials.response?.order_id,
                ...data,
              },
            }
          } catch (error) {
            const { request } = error as AxiosResponse
            const { status, statusText } = request as IError
            _api.dispatch(
              dialogsActions.dialogs_error_setDescription(
                statusText ? statusText : null
              )
            )
            _api.dispatch(
              dialogsActions.dialogs_error_setErrorCode(
                status ? status.toString() : '500'
              )
            )
            return {
              error: {
                status,
                statusText,
              },
            }
          }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_error_setDescription(
              statusText ? statusText : null
            )
          )
          _api.dispatch(
            dialogsActions.dialogs_error_setErrorCode(
              status ? status.toString() : '500'
            )
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),

    // https://api.omegatv.ua/pay/platon/pay_service
    get_platon_pay_credentials: builder.mutation<
      IGetPlatonPayCredentialsResponse,
      IGetPaymentRequest
    >({
      queryFn: async (_args, _api) => {
        const url = `${process.env.BASE_API_URL}/pay/platon/pay_service`
        const token = localStorage.getItem(USER_TOKEN)

        const formData = new FormData()
        formData.append('amount', _args.amount)
        formData.append('token', token)
        formData.append('pay_type', 1)

        try {
          const { data } = await axios.post<IGetPlatonPayCredentialsResponse>(
            url,
            formData
          )

          if (data && data.code !== '200') {
            _api.dispatch(dialogsActions.dialogs_error_setErrorCode(data.code))
            return { data }
          }

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_error_setDescription(
              statusText ? statusText : null
            )
          )
          _api.dispatch(
            dialogsActions.dialogs_error_setErrorCode(
              status ? status.toString() : '500'
            )
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),

    // https://api.omegatv.ua/pay/check_payment
    check_payment: builder.query<ICheckPaymentResponse, ICheckPaymentRequest>({
      queryFn: async (_args, _api) => {
        const url = `${process.env.BASE_API_URL}/pay/check_payment`
        const token = localStorage.getItem(USER_TOKEN)

        const formData = new FormData()
        formData.append('order_id', _args.order_id)
        formData.append('token', token)

        try {
          const { data } = await axios.post<ICheckPaymentResponse>(
            url,
            formData,
            {
              headers: {
                'X-Token-O': _args.order_id,
              },
            }
          )

          if (data && data.code !== '200') {
            if (data.code !== '4068')
              _api.dispatch(
                dialogsActions.dialogs_error_setErrorCode(data.code)
              )
            return { data }
          }

          if (data && data.response?.payment_result) {
            _api.dispatch(overlaysActions.overlays_payment_reset())
            const message = `${t('overlays:payment.success-payment')} ${
              data.response.payment_amount
            } ${t('common:currency')}`

            if (data.response.reauth) {
              _api.dispatch(
                dialogsActions.dialogs_reloadApp_setReloadAppIsOpened({
                  isOpened: true,
                  message: `${message}. ${t('overlays:payment.reload-app')}`,
                })
              )
            } else {
              _api.dispatch(appRightMenuActions.appRightMenu_reset())
              _api.dispatch(playerActions.player_hlsPlayer_setIsActive(true))
              _api.dispatch(
                authApi.util.invalidateTags(['profile_page', 'user_info'])
              )
              _api.dispatch(
                dialogsActions.dialogs_info_setInfoDialogText(message)
              )
              _api.dispatch(
                dialogsActions.dialogs_info_setInfoDialogIsOpened(true)
              )
            }
          }

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(overlaysActions.overlays_payment_reset())
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
      keepUnusedDataFor: 0,
    }),

    // https://cdnua01.hls.tv/api/privcy-policy
    get_privacy_policy: builder.query<
      IGetPrivacyPolicyResponse,
      IGetPrivacyPolicyRequest
    >({
      queryFn: async (_args, _api) => {
        const url = `${process.env.BASE_CDN_URL}/api/${
          !_args.customizationCompanyId
            ? 'privacy-policy'
            : 'b2b-privacy-policy'
        }`

        try {
          const { data } = await axios.get<IGetPrivacyPolicyResponse>(url)

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_error_setDescription(
              statusText ? statusText : null
            )
          )
          _api.dispatch(
            dialogsActions.dialogs_error_setErrorCode(
              status ? status.toString() : '500'
            )
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),
  }),
})

export const overlaysReducer = overlaysSlice.reducer
export const overlaysActions = overlaysSlice.actions

export const {
  useActivate_voucherMutation,
  useGet_liqpay_qrMutation,
  useGet_privacy_policyQuery,
  useGet_platon_pay_credentialsMutation,
  useLazyCheck_paymentQuery,
} = overlaysApi
