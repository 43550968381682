import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import axios, { AxiosResponse } from 'axios'
import {
  IKinozalFilmListInitialState,
  IKinozalFilmListResponse,
  IKinozalFilmListRequest,
  IKinozalFilmListTransformResponse,
  TKinozalFilmListSearchRequest,
  IKinozalFilmListPreviewSearchRequest,
} from '@assets/types/kinozal/kinozal.filmlist.types'
import { axiosBaseQueryTyped, IError } from '@store/axiosBaseQueryTyped'
import { AppState } from '@store/store'
import {
  BASE_COLUMN_NUMBER,
  BASE_KINOZAL_FILMLIST_PREVIEW_ITEMS_COUNT,
} from '@utils/vars'
import { kinozalCommonActions } from '@store/slices/kinozal/kinozal.common.slice'
import {
  IKinozalGetAllContentByCategoryRequest,
  IKinozalGetAllContentByCategoryResponse,
} from '@assets/types/kinozal/kinozal.common.types'

const initialState: IKinozalFilmListInitialState = {
  isFilmListActive: true,
  filters: {
    search: {
      isActive: false,
      overlay: {
        isOpened: false,
        input: {
          debouncedValue: '',
          isActive: true,
        },
        previewList: {
          isActive: false,
          itemIdx: 0,
        },
        showMoreIsActive: false,
      },
    },
    sort: {
      isActive: false,
      sortAsideIsActive: false,
      activeSortOptionIdx: 0,
    },
    filter: {
      isActive: false,
      filterAsideIsActive: false,
      years: {
        listIsActive: false,
        yearBtnIsActive: false,
        activeYearIdx: 0,
      },
      countries: {
        listIsActive: false,
        countryBtnIsActive: false,
        activeCountryIdx: 0,
      },
      resetBtnIsActive: false,
    },
  },
  genres: {
    activeGenreIdx: 0,
    isActive: false,
    resetIsActive: false,
  },
}

export const kinozalFilmListSlice = createSlice({
  name: 'kinozalFilmList',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(kinozalCommonActions.kinozalCommon_resetKinozalMode, () => initialState)
  },
  reducers: {
    kinozalFilmList_initialState: () => initialState,
    kinozalFilmList_setIsFilmListActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isFilmListActive = action.payload
    },
    kinozalFilmlist_noData: (state) => {
      state.isFilmListActive = false
      state.filters.search.isActive = true
      state.genres.isActive = false
      state.filters.sort.isActive = false
      state.filters.sort.sortAsideIsActive = false
      state.filters.filter.isActive = false
    },
    kinozalFilmlist_clickFilm: (state) => {
      state.isFilmListActive = true
      state.filters.search.isActive = false
      state.genres.isActive = false
      state.filters.sort.isActive = false
      state.filters.filter.isActive = false
    },

    // genres
    kinozalFilmlist_genres_setGenresIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.genres.isActive = action.payload
    },
    kinozalFilmlist_genres_setActiveGenreIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.genres.activeGenreIdx = action.payload
    },
    kinozalFilmlist_genres_incrementActiveGenreIdx: (state) => {
      state.genres.activeGenreIdx++
    },
    kinozalFilmlist_genres_decrementActiveGenreIdx: (state) => {
      state.genres.activeGenreIdx--
    },
    kinozalFilmlist_genres_setResetIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.genres.resetIsActive = action.payload
    },

    // filters
    kinozalFilmlist_filters_initialState: (state) => ({
      ...initialState,
      isFilmListActive: state.isFilmListActive,
      filters: {
        ...initialState.filters,
        filter: {
          ...initialState.filters.filter,
          isActive: state.filters.filter.isActive,
        },
        sort: {
          ...initialState.filters.sort,
          isActive: state.filters.sort.isActive,
        },
      },
    }),

    // search
    kinozalFilmlist_filters_search_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.filters.search.isActive = action.payload
    },
    kinozalFilmlist_filters_search_click: (state) => {
      state.filters.search.overlay.isOpened = true
      state.filters.search.isActive = true
      state.filters.sort.isActive = false
      state.filters.filter.isActive = false
      state.genres.isActive = false
      state.isFilmListActive = false
    },
    kinozalFilmlist_filters_search_overlay_setIsOpened: (
      state,
      action: PayloadAction<
        IKinozalFilmListInitialState['filters']['search']['overlay']['isOpened']
      >
    ) => {
      if (!action.payload) {
        state.filters.search.overlay = {
          ...initialState.filters.search.overlay,
          input: {
            ...initialState.filters.search.overlay.input,
            debouncedValue: state.filters.search.overlay.input.debouncedValue,
          },
        }
        state.filters.search.isActive = true
        return
      }
      state.filters.search.overlay.isOpened = action.payload
    },
    kinozalFilmlist_filters_search_overlay_input_setDebouncedValue: (
      state,
      action: PayloadAction<
        IKinozalFilmListInitialState['filters']['search']['overlay']['input']['debouncedValue']
      >
    ) => {
      state.filters.search.overlay.input.debouncedValue = action.payload
    },
    kinozalFilmlist_filters_search_overlay_input_setIsActive: (
      state,
      action: PayloadAction<
        IKinozalFilmListInitialState['filters']['search']['overlay']['input']['isActive']
      >
    ) => {
      state.filters.search.overlay.input.isActive = action.payload
    },
    kinozalFilmlist_filters_search_overlay_input_click: (state) => {
      state.filters.search.overlay.input.isActive = true
      state.filters.search.overlay.previewList =
        initialState.filters.search.overlay.previewList
      state.filters.search.overlay.showMoreIsActive = false
    },
    kinozalFilmlist_filters_search_overlay_previewList_setIsActive: (
      state,
      action: PayloadAction<
        IKinozalFilmListInitialState['filters']['search']['overlay']['previewList']['isActive']
      >
    ) => {
      state.filters.search.overlay.previewList.isActive = action.payload
    },
    kinozalFilmlist_filters_search_overlay_previewList_setItemIdx: (
      state,
      action: PayloadAction<
        IKinozalFilmListInitialState['filters']['search']['overlay']['previewList']['itemIdx']
      >
    ) => {
      state.filters.search.overlay.previewList.itemIdx = action.payload
    },
    kinozalFilmlist_filters_search_overlay_previewList_showMoreSetIsActive: (
      state,
      action: PayloadAction<
        IKinozalFilmListInitialState['filters']['search']['overlay']['showMoreIsActive']
      >
    ) => {
      state.filters.search.overlay.showMoreIsActive = action.payload
    },

    // sort
    kinozalFilmlist_filters_sort_initialState: (state) => {
      state.filters.sort.activeSortOptionIdx =
        initialState.filters.sort.activeSortOptionIdx
      state.filters.sort.sortAsideIsActive =
        initialState.filters.sort.sortAsideIsActive
    },
    kinozalFilmlist_filters_sort_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.filters.sort.isActive = action.payload
    },
    kinozalFilmlist_filters_sort_click: (state) => {
      state.filters.sort.sortAsideIsActive = true
      state.filters.sort.isActive = true
      state.genres.isActive = false
      state.filters.search.isActive = false
      state.filters.filter.isActive = false
      state.isFilmListActive = false
    },
    kinozalFilmlist_filters_sort_setSortAsideIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.filters.sort.sortAsideIsActive = action.payload
    },
    kinozalFilmlist_filters_sort_setActiveSortOptionIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.filters.sort.activeSortOptionIdx = action.payload
    },
    kinozalFilmlist_filters_sort_incrementActiveSortOptionIdx: (state) => {
      state.filters.sort.activeSortOptionIdx++
    },
    kinozalFilmlist_filters_sort_decrementActiveSortOptionIdx: (state) => {
      state.filters.sort.activeSortOptionIdx--
    },

    // filter
    kinozalFilmlist_filters_filter_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.filters.filter.isActive = action.payload
    },
    kinozalFilmlist_filters_filter_click: (state) => {
      state.filters.filter.isActive = true
      state.filters.filter.filterAsideIsActive = true
      state.filters.filter.years.yearBtnIsActive = true
      state.genres.isActive = false
      state.filters.search.isActive = false
      state.filters.sort.isActive = false
      state.isFilmListActive = false
    },
    kinozalFilmlist_filters_filter_setFilterAsideIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.filters.filter.filterAsideIsActive = action.payload
    },
    kinozalFilmlist_filters_filter_years_setYearBtnIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.filters.filter.years.yearBtnIsActive = action.payload
    },
    kinozalFilmlist_filters_filter_years_setListIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.filters.filter.years.listIsActive = action.payload
    },
    kinozalFilmlist_filters_filter_countries_setCountryBtnIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.filters.filter.countries.countryBtnIsActive = action.payload
    },
    kinozalFilmlist_filters_filter_countries_setListIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.filters.filter.countries.listIsActive = action.payload
    },
    kinozalFilmlist_filters_filter_setResetBtnIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.filters.filter.resetBtnIsActive = action.payload
    },
    kinozalFilmlist_filters_filter_years_setActiveYearIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.filters.filter.years.activeYearIdx = action.payload
    },
    kinozalFilmlist_filters_filter_years_incrementActiveYearIdx: (state) => {
      state.filters.filter.years.activeYearIdx++
    },
    kinozalFilmlist_filters_filter_years_decrementActiveYearIdx: (state) => {
      state.filters.filter.years.activeYearIdx--
    },
    kinozalFilmlist_filters_filter_countries_setActiveCountryIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.filters.filter.countries.activeCountryIdx = action.payload
    },
    kinozalFilmlist_filters_filter_countries_incrementActiveCountryIdx: (
      state
    ) => {
      state.filters.filter.countries.activeCountryIdx++
    },
    kinozalFilmlist_filters_filter_countries_decrementActiveCountryIdx: (
      state
    ) => {
      state.filters.filter.countries.activeCountryIdx--
    },
  },
})

export const kinozalFilmListApi = createApi({
  reducerPath: 'kinozalFilmListApi',
  baseQuery: axiosBaseQueryTyped({
    baseUrl: '',
  }),
  endpoints: (builder) => ({
    // https://${baseUrl}/${playlist}/category/${_args.categoryId}/${_args.page}/list.json?lang=${_args.lang}
    get_films_by_category: builder.query<
      IKinozalFilmListResponse,
      IKinozalFilmListRequest
    >({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState
        const baseUrl = appState().auth.authSuccessData?.archive_megogo
          .base_url as string
        const playlist = appState().auth.authSuccessData?.playlist as string

        const genres = Array.isArray(_args.genre)
          ? _args.genre.join(',')
          : _args.genre

        const url = `${baseUrl}/${playlist}/category/${_args.categoryId}/${
          _args.page || '1'
        }/list.json?lang=${_args.language}${
          genres ? `&genre=${genres}` : ''
        }&sort=${_args.sort || 'popular'}${
          _args.year_max ? `&year_max=${_args.year_max}` : ''
        }${_args.year_min ? `&year_min=${_args.year_min}` : ''}${
          _args.country ? `&country=${_args.country}` : ''
        }`

        try {
          const { data } = await axios.get<IKinozalFilmListResponse>(url)

          if (data.status !== 'ok') {
            return { data }
          }

          if (!data.result?.items.length) {
            _api.dispatch(kinozalFilmListActions.kinozalFilmlist_noData())
          } else {
            _api.dispatch(kinozalFilmListActions.kinozalFilmList_initialState())
          }
          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
      },
      merge: (currentCache, newItems, { arg }) => {
        if (!newItems.result || !currentCache.result) return
        if (arg.page === '1') {
          return newItems
        }
        currentCache.result.current_page = newItems.result.current_page
        if (newItems.result.items)
          currentCache.result.items.push(...newItems.result.items)
      },
      keepUnusedDataFor: 21600,
    }),

    // https://${baseUrl}/${playlist}/search/list.json?lang=${_args.lang}&${_args.query}
    get_preview_films_by_search_query: builder.query<
      IKinozalFilmListTransformResponse,
      IKinozalFilmListPreviewSearchRequest
    >({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState
        const baseUrl = appState().auth.authSuccessData?.archive_megogo
          .base_url as string
        const playlist = appState().auth.authSuccessData?.playlist as string

        const url = `${baseUrl}/${playlist}/search/list.json?lang=${_args.language}&page=${_args.page}&query=${_args.query}`

        try {
          const { data } = await axios.get<IKinozalFilmListResponse>(url)

          return {
            data: {
              ...data,
              slicedItems: _args.sliceItems
                ? data?.result?.items.slice(
                  0,
                  BASE_KINOZAL_FILMLIST_PREVIEW_ITEMS_COUNT
                )
                : undefined,
            },
          }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),

    // https://${baseUrl}/${playlist}/search/list.json?lang=${_args.lang}&${_args.query}
    get_films_by_search_query: builder.query<
      IKinozalFilmListResponse,
      TKinozalFilmListSearchRequest
    >({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState
        const baseUrl = appState().auth.authSuccessData?.archive_megogo
          .base_url as string
        const playlist = appState().auth.authSuccessData?.playlist as string

        const url = `${baseUrl}/${playlist}/search/list.json?lang=${_args.language}&page=${_args.page}&query=${_args.query}`

        try {
          const { data } = await axios.get<IKinozalFilmListResponse>(url)

          if (!data.result?.items.length) {
            _api.dispatch(kinozalFilmListActions.kinozalFilmlist_noData())
          } else {
            _api.dispatch(kinozalFilmListActions.kinozalFilmList_initialState())
          }

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
      },
      merge: (currentCache, newItems, { arg }) => {
        if (!newItems.result || !currentCache.result) return
        if (arg.page === 1) {
          return newItems
        }
        currentCache.result.current_page = newItems.result.current_page
        if (newItems.result.items)
          currentCache.result.items.push(...newItems.result.items)
      },
      keepUnusedDataFor: 3600,
    }),

    get_all_content_by_category: builder.query<
      IKinozalGetAllContentByCategoryResponse[],
      IKinozalGetAllContentByCategoryRequest
    >({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState

        const baseUrl = appState().auth.authSuccessData?.archive_megogo
          .base_url as string
        const playlist = appState().auth.authSuccessData?.playlist as string

        if (!_args.categories.length) return { data: [] }

        const genres = Array.isArray(_args.genre)
          ? _args.genre.join(',')
          : _args.genre

        const queryParams = `lang=${_args.language}${
          genres ? `&genre=${genres}` : ''
        }&sort=${_args.sort || 'popular'}${
          _args.year_max ? `&year_max=${_args.year_max}` : ''
        }${_args.year_min ? `&year_min=${_args.year_min}` : ''}${
          _args.country ? `&country=${_args.country}` : ''
        }`
        try {
          const promises = _args.categories?.map((category) => {
            const url = `${baseUrl}/${playlist}/category/${category.id}/1/list.json?${queryParams}`
            return axios.get<IKinozalFilmListResponse>(url)
          })

          if (!promises) return { data: [] }

          const response = await Promise.all(promises)

          const data: IKinozalGetAllContentByCategoryResponse[] = response
            .map((response, index) => {
              const responseItems = response.data.result?.items || []
              const items =
                responseItems && responseItems.length > BASE_COLUMN_NUMBER
                  ? responseItems.slice(0, BASE_COLUMN_NUMBER)
                  : responseItems
              return {
                id: _args.categories[index].id,
                title: _args.categories[index].title,
                items,
              }
            })
            .filter((category) => !!category.items.length)

          if (!data.length) {
            _api.dispatch(kinozalFilmListActions.kinozalFilmlist_noData())
          } else {
            _api.dispatch(kinozalFilmListActions.kinozalFilmList_initialState())
            _api.dispatch(
              kinozalCommonActions.kinozalCommon_allContent_setIsActive(true)
            )
          }

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),
  }),
})

export const {
  useGet_films_by_categoryQuery,
  useGet_preview_films_by_search_queryQuery,
  useGet_films_by_search_queryQuery,
  useGet_all_content_by_categoryQuery,
} = kinozalFilmListApi

export const kinozalFilmListReducer = kinozalFilmListSlice.reducer
export const kinozalFilmListActions = kinozalFilmListSlice.actions
