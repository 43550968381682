import { KinozalItemType } from '@assets/types/kinozal/kinozal.filmlist.types'
import { IKinozalPlaylistByIDResponse } from '@assets/types/kinozal/kinozal.details.types'

export const getKinozalVideoLink = (
  recentlyArr: KinozalItemType[],
  playlistData: IKinozalPlaylistByIDResponse,
): { link: string; idx: number } => {
  const film = recentlyArr.find(
    (film) => film.id === (playlistData.result.root_video_id || playlistData.result.video_id)
  )

  if (film) {
    const trackIdx = playlistData.result.audio_tracks.findIndex(
      (track) => track.id === film?.audioTrackId
    )
    if(trackIdx !== -1) return { link: playlistData.result.audio_tracks[trackIdx].src, idx: trackIdx }
  }
  
  const startTrackIdx = playlistData.result.audio_tracks.findIndex((track) => track.is_active)
  return { link: playlistData.result.src, idx: startTrackIdx }
}
