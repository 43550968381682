import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import axios, { AxiosResponse } from 'axios'

import {
  IGetFilmsRequest,
  IGetFilmsResponse,
  IMoviesFilmListInitialState,
} from '@assets/types/movies/filmlist.types'

import { axiosBaseQueryTyped, IError } from '@store/axiosBaseQueryTyped'
import { AppState } from '@store/store'
import { moviesAsideActions } from '@store/slices/movies/aside.slice'
import { moviesActions } from '@store/slices/movies/movies.slice'

const initialState: IMoviesFilmListInitialState = {
  isActive: false,
}

export const moviesFilmListSlice = createSlice({
  name: 'moviesFilmList',
  initialState,
  reducers: {
    movies_filmList_filmListReset: () => initialState,
    movies_filmList_setFilmListIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isActive = action.payload
    },
    movies_filmList_click: (state) => {
      state.isActive = true
    },
  },
})

export const moviesFilmListApi = createApi({
  reducerPath: 'moviesFilmListApi',
  baseQuery: axiosBaseQueryTyped({
    baseUrl: process.env.BASE_CDN_URL,
  }),
  endpoints: (builder) => ({
    // <CDN_DOMAIN>/v1/catalog/<playlist_id>/<category_id>/<page_num>/list.json
    get_movies_films_by_category: builder.query<
      IGetFilmsResponse,
      IGetFilmsRequest
    >({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState
        const playlist = appState().auth.authSuccessData?.playlist as string

        const url = `${process.env.BASE_CDN_URL}/v1/catalog/${playlist}/${
          _args.category
        }/${_args.page || '1'}/list.json?filter[hd]=${
          _args.isOnlyHd || '0'
        }&sort=${_args.sort || 'DEFAULT'}&language=${
          _args.language
        }&items_per_page=30`
        try {
          const { data } = await axios.get<IGetFilmsResponse>(url)

          if (data.items.length) {
            if (!appState().moviesFilmList.isActive) {
              _api.dispatch(moviesActions.movies_resetOnlyHdSortBtns())
              _api.dispatch(
                moviesFilmListActions.movies_filmList_setFilmListIsActive(true)
              )
            }
            if (appState().moviesAside.asideIsActive) {
              _api.dispatch(
                moviesAsideActions.movies_aside_setOnlyAsideIsActive(false)
              )
            }
          } else {
            _api.dispatch(moviesActions.movies_resetOnlyHdSortBtns())
            _api.dispatch(
              moviesAsideActions.movies_aside_setOnlyAsideIsActive(true)
            )
          }
          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.page === '1') {
          return newItems
        }
        currentCache.page = newItems.page
        if (newItems.items) currentCache.items.push(...newItems.items)
      },
      keepUnusedDataFor: 21600,
    }),
  }),
})
export const moviesFilmListReducer = moviesFilmListSlice.reducer
export const moviesFilmListActions = moviesFilmListSlice.actions

export const {
  useGet_movies_films_by_categoryQuery,
  useLazyGet_movies_films_by_categoryQuery,
} = moviesFilmListApi
