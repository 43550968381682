import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import axios, { AxiosResponse } from 'axios'
import FormData from 'form-data'
import { t } from 'i18next'

import {
  IAppInitialState,
  ICommonPlansResponse,
  IFilteredPlan,
  IFilteredUserPlans,
  IUserPlansResponse,
  IChangeUserPlanResponse,
  IEditDeviceRequest,
  IEditDeviceResponse,
  IUnbindDeviceRequest,
  IUnbindDeviceResponse,
  TLanguage,
  IGenerateQrRequest,
  IGenerateQrResponse,
  TCompanyIds,
} from '@assets/types/app.types'

import { AppState } from '@store/store'
import { authApi } from '@store/slices/auth.slice'
import { dialogsActions } from '@store/slices/dialogs.slice'
import { axiosBaseQueryTyped, IError } from '@store/axiosBaseQueryTyped'

import {
  CHANNEL_NAME_IN_THE_LIST,
  PROMO_PLAN_ID,
  USER_TOKEN,
  LAST_WATCHED_CATCHUP_PROGRAM_DATA,
} from '@utils/vars'

import { useGetItemFromLocalStorage } from '@hooks/app/localStorage/useGetItemFromLocalStorage'

import {
  IChannel,
  ILastWatchedCatchupProgramData,
} from '@assets/types/player.types'

const initialState: IAppInitialState = {
  language: 'ua',
  isFullscreenMode: false,
  isDefaultPlayerMode: true,
  customizationCompanyId: null,
  channelsIsUnblocked: false,
  settings: {
    channelNameInTheList: true,
  },
  findChannelByKey: {
    channels: null,
    isOpened: false,
    channelKey: null,
    activeChannelIdx: 0,
  },
  filmGrid: {
    activeCell: { rowIndex: 0, columnIndex: 0 },
  },
  [LAST_WATCHED_CATCHUP_PROGRAM_DATA]: null,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    app_logout: (state) => state,

    // fullscreen mode
    app_setIsFullscreenMode: (state, action: PayloadAction<boolean>) => {
      state.isFullscreenMode = action.payload
    },

    // isDefaultPlayerMode 
    app_setIsDefaultPlayerMode: (state, action: PayloadAction<boolean>) => {
      state.isDefaultPlayerMode = action.payload
    },

    // filmGridActiveCell
    app_setFilmsGridInitialState: (state) => {
      state.filmGrid.activeCell = initialState.filmGrid.activeCell
    },

    app_setFilmGridActiveCell: (
      state,
      action: PayloadAction<IAppInitialState['filmGrid']['activeCell']>
    ) => {
      state.filmGrid.activeCell = action.payload
    },

    // set customizationCompanyId
    app_setCustomizationCompanyId: (
      state,
      action: PayloadAction<TCompanyIds>
    ) => {
      state.customizationCompanyId = action.payload
    },

    app_setLanguage: (state, action: PayloadAction<TLanguage>) => {
      state.language = action.payload
    },
    app_setChannelsIsUnblocked: (state, action: PayloadAction<boolean>) => {
      state.channelsIsUnblocked = action.payload
    },

    // find channel by key
    app_findChannelByKey_reset: (state) => {
      state.findChannelByKey = initialState.findChannelByKey
    },
    app_findChannelByKey_setIsOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.findChannelByKey.isOpened = action.payload
    },
    app_findChannelByKey_setChannels: (
      state,
      action: PayloadAction<IChannel[]>
    ) => {
      state.findChannelByKey.channels = action.payload
    },
    app_findChannelByKey_setActiveChannelIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.findChannelByKey.activeChannelIdx = action.payload
      if (state.findChannelByKey.channels)
        state.findChannelByKey.channelKey =
          state.findChannelByKey.channels[
            state.findChannelByKey.activeChannelIdx
          ].key.toString()
    },
    app_findChannelByKey_incrementActiveChannelIdx: (state) => {
      state.findChannelByKey.activeChannelIdx++
      if (state.findChannelByKey.channels)
        state.findChannelByKey.channelKey =
          state.findChannelByKey.channels[
            state.findChannelByKey.activeChannelIdx
          ].key.toString()
    },
    app_findChannelByKey_decrementActiveChannelIdx: (state) => {
      state.findChannelByKey.activeChannelIdx--
      if (state.findChannelByKey.channels)
        state.findChannelByKey.channelKey =
          state.findChannelByKey.channels[
            state.findChannelByKey.activeChannelIdx
          ].key.toString()
    },
    app_findChannelByKey_pushChannelKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.findChannelByKey.channelKey = state.findChannelByKey.channelKey
        ? state.findChannelByKey.channelKey + action.payload
        : action.payload
    },
    app_findChannelByKey_sliceChannelKey: (state) => {
      if (state.findChannelByKey.channelKey)
        state.findChannelByKey.channelKey =
          state.findChannelByKey.channelKey.slice(
            0,
            state.findChannelByKey.channelKey.length - 1
          )
    },

    // set last watched catchup program to localStorage
    app_setLastWatchedCatchupProgram: (state) => state,

    // persist
    app_setLastWatchedCatchupProgramData: (
      state,
      action: PayloadAction<ILastWatchedCatchupProgramData>
    ) => {
      state.lastWatchedCatchupProgramData = action.payload
    },
    app_removeLastWatchedCatchupProgramData: (state) => {
      state.lastWatchedCatchupProgramData =
        initialState.lastWatchedCatchupProgramData
    },

    // settings
    app_settings_reset: (state) => {
      state.settings = initialState.settings
    },
    app_settings_toggleChannelNameInTheList: (state) => {
      state.settings.channelNameInTheList = !state.settings.channelNameInTheList
    },
    app_settings_loadSettingsFromLocalStorage: (state) => {
      const channelNameInTheList = useGetItemFromLocalStorage(
        CHANNEL_NAME_IN_THE_LIST
      )
      if (channelNameInTheList && channelNameInTheList === 'false')
        state.settings.channelNameInTheList = false
    },
  },
})

export const appApi = createApi({
  reducerPath: 'appApi',
  baseQuery: axiosBaseQueryTyped({
    baseUrl: process.env.BASE_CDN_URL,
  }),
  tagTypes: ['Plans', 'Devices'],
  endpoints: (builder) => ({
    // https://api.omegatv.ua/tarif_info/price
    get_common_plans: builder.query<IFilteredPlan[], void>({
      queryFn: async (_, _api) => {
        const url = `${process.env.BASE_API_URL}/tarif_info/price`

        try {
          const { data } = await axios.get<ICommonPlansResponse>(url)

          if (data && data.code !== '200') {
            _api.dispatch(
              dialogsActions.dialogs_retry_setRetryDialogIsOpened(true)
            )
            return {
              error: {
                status: +data.code,
                statusText: undefined,
              },
            }
          }

          const filteredPlans = data.response?.tarif_price_list.filter(
            (plan) => plan.id === '1' || plan.id === '2' || plan.id === '8'
          ) as IFilteredPlan[]

          return { data: filteredPlans }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_retry_setRetryDialogIsOpened(true)
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),

    // https://api.omegatv.ua/tarif_info/list
    get_user_plans: builder.query<IFilteredUserPlans[], void>({
      queryFn: async (_, _api) => {
        const url = `${process.env.BASE_API_URL}/tarif_info/list`
        const token = localStorage.getItem(USER_TOKEN)
        const formData = new FormData()
        formData.append('token', token)

        try {
          const { data } = await axios.post<IUserPlansResponse>(url, formData)

          if (data && data.code !== '200') {
            _api.dispatch(
              dialogsActions.dialogs_retry_setRetryDialogIsOpened(true)
            )
            return {
              error: {
                status: +data.code,
                statusText: undefined,
              },
            }
          }

          const filteredPlans = data.response?.tarif_info_list.filter(
            (plan) => plan.id !== PROMO_PLAN_ID
          ) as IFilteredUserPlans[]

          return { data: filteredPlans }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_retry_setRetryDialogIsOpened(true)
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
      providesTags: ['Plans'],
    }),

    // https://api.omegatv.ua/tarif_change
    change_user_plan: builder.mutation<
      IChangeUserPlanResponse,
      { tarif_id: string }
    >({
      queryFn: async (_args, _api) => {
        const appState = _api.getState() as AppState

        const url = `${process.env.BASE_API_URL}/tarif_change`
        const token = localStorage.getItem(USER_TOKEN)
        const formData = new FormData()
        formData.append('token', token)
        formData.append('tarif_id', _args.tarif_id)

        try {
          const { data } = await axios.post<IChangeUserPlanResponse>(
            url,
            formData
          )

          if (data && data.code !== '200') {
            _api.dispatch(dialogsActions.dialogs_error_setErrorCode(data.code))
            return { data }
          }

          if (data?.response?.tarif_diff === 2) {
            const changedPlan = appState.dialogs.confirm.message
            _api.dispatch(dialogsActions.dialogs_confirm_resetState())
            _api.dispatch(
              dialogsActions.dialogs_reloadApp_setReloadAppIsOpened({
                isOpened: true,
                message: `${t(
                  'dialogs:info-dialog.plan-change-success'
                )} "${changedPlan}". ${t('dialogs:info-dialog.reload-app')}`,
              })
            )
            return { data }
          }

          if (data?.response?.tarif_diff === 1) {
            _api.dispatch(
              dialogsActions.dialogs_info_setInfoDialogText(
                t('dialogs:info-dialog.plan-change-delay')
              )
            )
            _api.dispatch(dialogsActions.dialogs_confirm_resetState())
            _api.dispatch(
              dialogsActions.dialogs_info_setInfoDialogIsOpened(true)
            )
            return { data }
          }

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_error_setDescription(
              statusText ? statusText : null
            )
          )
          _api.dispatch(
            dialogsActions.dialogs_error_setErrorCode(
              status ? status.toString() : '500'
            )
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
      invalidatesTags: ['Plans'],
    }),

    // https://api.omegatv.ua/device/add_comment
    edit_device: builder.mutation<IEditDeviceResponse, IEditDeviceRequest>({
      queryFn: async (_args, _api) => {
        const url = `${process.env.BASE_API_URL}/device/add_comment`
        const token = localStorage.getItem(USER_TOKEN)
        const formData = new FormData()

        formData.append('token', token)
        formData.append('uniq', _args.uniq)
        formData.append('comment', _args.comment)

        try {
          const { data } = await axios.post<IEditDeviceResponse>(url, formData)

          if (data && data.code !== '200') {
            _api.dispatch(dialogsActions.dialogs_error_setErrorCode(data.code))
            return { data }
          }

          await _api.dispatch(authApi.util.invalidateTags(['user_info']))
          await _api.dispatch(authApi.util.invalidateTags(['profile_page']))
          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_error_setDescription(
              statusText ? statusText : null
            )
          )
          _api.dispatch(
            dialogsActions.dialogs_error_setErrorCode(
              status ? status.toString() : '500'
            )
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),

    // https://api.omegatv.ua/device/deactivate_device
    unbind_device: builder.mutation<
      IUnbindDeviceResponse,
      IUnbindDeviceRequest
    >({
      queryFn: async (_args, _api) => {
        const appState = _api.getState as () => AppState
        const currentDeviceUniq = appState().auth.authSuccessData?.uniq

        const url = `${process.env.BASE_API_URL}/device/deactivate_device`
        const token = localStorage.getItem(USER_TOKEN)
        const formData = new FormData()

        formData.append('token', token)
        formData.append('uniq', _args.uniq)

        try {
          const { data } = await axios.post<IUnbindDeviceResponse>(
            url,
            formData
          )

          if (data && data.code !== '200') {
            _api.dispatch(dialogsActions.dialogs_error_setErrorCode(data.code))
            return { data }
          }

          // logout if it's current device
          if (currentDeviceUniq === _args.uniq) {
            _api.dispatch(appActions.app_logout())
            return { data }
          }

          await _api.dispatch(authApi.util.invalidateTags(['user_info']))
          await _api.dispatch(authApi.util.invalidateTags(['profile_page']))

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          _api.dispatch(
            dialogsActions.dialogs_error_setDescription(
              statusText ? statusText : null
            )
          )
          _api.dispatch(
            dialogsActions.dialogs_error_setErrorCode(
              status ? status.toString() : '500'
            )
          )
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),

    // https://webapp.hls.tv/api/generate-qr
    generate_qr: builder.query<IGenerateQrResponse, IGenerateQrRequest>({
      queryFn: async (_args) => {
        try {
          const url = `${process.env.BASE_REACT_PLAYER_API}/api/generate-qr`

          const { data } = await axios.post<IGenerateQrResponse>(url, _args)

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError
          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),
  }),
})

export const appReducer = appSlice.reducer
export const appActions = appSlice.actions

export const {
  useGet_common_plansQuery,
  useChange_user_planMutation,
  useGet_user_plansQuery,
  useEdit_deviceMutation,
  useUnbind_deviceMutation,
} = appApi
