import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  IMoviesInitialState,
  IRecentlyViewedEpisode,
  RecentlyViewedFilmType,
} from '@assets/types/movies/movies.types'

import {
  MOVIES_RECENTLY_VIEWED_FILMS,
  MOVIES_RECENTLY_VIEWED_EPISODES,
} from '@utils/vars'
import { getTimeNow } from '@utils/getTimeNow'
import { moviesFilmListActions } from './filmlist.slice'


const initialState: IMoviesInitialState = {
  isMoviesMode: false,
  isShow: false,
  [MOVIES_RECENTLY_VIEWED_FILMS]: [],
  [MOVIES_RECENTLY_VIEWED_EPISODES]: [],
  rightMenu: {
    isActive: false,
    index: 0,
  },
  onlyHdBtnIsActive: false,
  sortBtnIsActive: false,
  currentRoute: null,
}

export const moviesSlice = createSlice({
  name: 'movies',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(moviesFilmListActions.movies_filmList_click, (state) => {
      state.onlyHdBtnIsActive = false
      state.sortBtnIsActive = false
    })
  },
  reducers: {
    movies_reset: (state) => ({
      ...initialState,
      moviesRecentlyViewedEpisodes: state.moviesRecentlyViewedEpisodes,
      moviesRecentlyViewedFilms: state.moviesRecentlyViewedFilms
    }),
    // recently viewed persist
    movies_setRecentlyViewedEpisode: (
      state,
      action: PayloadAction<IRecentlyViewedEpisode>
    ) => {
      const isAlreadyViewed = state.moviesRecentlyViewedEpisodes?.find(
        (episode) => episode.id === action.payload.id
      )
      if (!isAlreadyViewed) {
        state.moviesRecentlyViewedEpisodes?.push(action.payload)
      }
    },
    movies_setRecentlyViewedFilm: (
      state,
      action: PayloadAction<RecentlyViewedFilmType>
    ) => {
      const isAlreadyViewed = state.moviesRecentlyViewedFilms?.find(
        (film) => film.id === action.payload.id
      )
      if (!isAlreadyViewed) {
        state.moviesRecentlyViewedFilms?.push(action.payload)
      }
    },
    movies_filterRecentlyViewedItems: (state) => {
      if (state.moviesRecentlyViewedFilms.length) {
        state.moviesRecentlyViewedFilms =
          state.moviesRecentlyViewedFilms.filter(
            (film) => getTimeNow() - film.start_at <= film.ttl
          )
      }
    },

    movies_setIsMoviesMode: (state, action: PayloadAction<boolean>) => {
      state.isMoviesMode = action.payload
    },
    movies_setIsShow: (state, action: PayloadAction<boolean>) => {
      state.isShow = action.payload
    },

    // Right Menu
    movies_setRightMenuIsActive: (state, action: PayloadAction<boolean>) => {
      state.rightMenu.isActive = action.payload
    },

    movies_setRightMenuActiveElementIndex: (
      state,
      action: PayloadAction<number>
    ) => {
      state.rightMenu.index = action.payload
    },

    movies_setOnlyHdBtnIsActive: (state, action: PayloadAction<boolean>) => {
      state.onlyHdBtnIsActive = action.payload
    },

    movies_setSortBtnIsActive: (state, action: PayloadAction<boolean>) => {
      state.sortBtnIsActive = action.payload
    },

    movies_resetOnlyHdSortBtns: (state) => {
      state.sortBtnIsActive = false
      state.onlyHdBtnIsActive = false
    },

    // current route
    movies_setCurrentRouteName: (
      state,
      action: PayloadAction<IMoviesInitialState['currentRoute']>
    ) => {
      state.currentRoute = action.payload
    },
  },
})

export const moviesReducer = moviesSlice.reducer
export const moviesActions = moviesSlice.actions
