import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/dist/query/react'
import axios from 'axios'
// import dayjs from 'dayjs'

import {
  ICheckConnectionSpeedResponse,
  IRightMenuInitialState,
} from '@assets/types/right-menu.types'
import { TLanguage } from '@assets/types/app.types'
import { axiosBaseQuery } from '@store/axiosBaseQuery'

const initialState: IRightMenuInitialState = {
  mainMenu: {
    isOpened: false,
    items: {
      parentalControlIsActive: false,
      paymentIsActive: false,
      plansIsActive: false,
      settingsIsActive: false,
      devicesIsActive: false,
      appInfoIsActive: false,
    },
  },
  subMenu: {
    isOpened: false,
    payment: {
      isShown: false,
      isActive: false,
      balance: {
        isActive: false,
      },
      voucher: {
        isActive: false,
      },
    },
    plans: {
      isShown: false,
      isActive: false,
      activePlanIdx: 0,
    },
    settings: {
      isShown: false,
      isActive: false,
      language: {
        isActive: false,
        position: 'ua',
      },
      channelNameInTheList: {
        isActive: false,
      },
      resetSettings: {
        isActive: false,
      },
      checkSpeed: {
        isActive: false,
      },
      logout: {
        isActive: false,
      },
    },
    devices: {
      isShown: false,
      isActive: false,
      activeDeviceIdx: 0,
    },
    appInfo: {
      isShown: false,
      isActive: false,
      privacyPolicy: {
        isActive: false,
      },
    },
  },
}

export const appRightMenuSlice = createSlice({
  name: 'appRightMenu',
  initialState,
  reducers: {
    // Main menu
    appRightMenu_reset: () => initialState,
    appRightMenu_mainMeu_partialReset: (state) => {
      state.mainMenu.items = initialState.mainMenu.items
    },
    appRightMenu_mainMenu_setMainMenuIsOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.mainMenu.isOpened = action.payload
    },
    appRightMenu_mainMenu_items_setParentalControlIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.mainMenu.items.parentalControlIsActive = action.payload
    },
    appRightMenu_mainMenu_items_setPaymentIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.mainMenu.items.paymentIsActive = action.payload
    },
    appRightMenu_mainMenu_items_setPlansIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.mainMenu.items.plansIsActive = action.payload
    },
    appRightMenu_mainMenu_items_setSettingsIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.mainMenu.items.settingsIsActive = action.payload
    },
    appRightMenu_mainMenu_items_setDevicesIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.mainMenu.items.devicesIsActive = action.payload
    },
    appRightMenu_mainMenu_items_setAppInfoIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.mainMenu.items.appInfoIsActive = action.payload
    },

    // Sub menu
    appRightMenu_subMenu_setIsOpened: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.subMenu.isOpened = action.payload
    },
    appRightMenu_subMenu_partialReset: (state) => {
      state.subMenu.appInfo = initialState.subMenu.appInfo
      state.subMenu.devices = initialState.subMenu.devices
      state.subMenu.payment = initialState.subMenu.payment
      state.subMenu.plans = initialState.subMenu.plans
      state.subMenu.settings = initialState.subMenu.settings
    },
    appRightMenu_subMenu_reset: (state) => {
      state.subMenu = initialState.subMenu
    },

    // payment
    appRightMenu_subMenu_payment_click: (state) => {
      if (state.mainMenu.items.paymentIsActive)
        state.mainMenu.items.paymentIsActive = false
      if (!state.subMenu.payment.isActive)
        state.subMenu.payment.isActive = true
    },
    appRightMenu_subMenu_payment_openPayment: (state) => {
      state.subMenu.payment.isShown = true
      if (!state.subMenu.isOpened) state.subMenu.isOpened = true
    },
    appRightMenu_subMenu_payment_togglePaymentIsActive: (state) => {
      state.mainMenu.items.paymentIsActive =
        !state.mainMenu.items.paymentIsActive
      state.subMenu.payment.isActive = !state.mainMenu.items.paymentIsActive
    },
    appRightMenu_subMenu_payment_partialReset: (state) => {
      state.subMenu.payment.balance = initialState.subMenu.payment.balance
      state.subMenu.payment.voucher = initialState.subMenu.payment.voucher
    },
    appRightMenu_subMenu_payment_balance_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.subMenu.payment.balance.isActive = action.payload
    },
    appRightMenu_subMenu_payment_voucher_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.subMenu.payment.voucher.isActive = action.payload
    },

    // plans
    appRightMenu_subMenu_plans_openPlans: (state) => {
      state.subMenu.plans.isShown = true
      if (!state.subMenu.isOpened) state.subMenu.isOpened = true
    },
    appRightMenu_subMenu_plans_togglePlansIsActive: (state) => {
      state.mainMenu.items.plansIsActive = !state.mainMenu.items.plansIsActive
      state.subMenu.plans.isActive = !state.subMenu.plans.isActive
    },
    appRightMenu_subMenu_plans_incrementActivePlanIdx: (state) => {
      state.subMenu.plans.activePlanIdx++
    },
    appRightMenu_subMenu_plans_decrementActivePlanIdx: (state) => {
      state.subMenu.plans.activePlanIdx--
    },
    appRightMenu_subMenu_plans_setActivePlanIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.subMenu.plans.activePlanIdx = action.payload
    },
    appRightMenu_subMenu_plans_click: (state, action: PayloadAction<number>) => {
      if (state.mainMenu.items.plansIsActive)
        state.mainMenu.items.plansIsActive = false
      if (!state.subMenu.plans.isActive)
        state.subMenu.plans.isActive = true
      state.subMenu.plans.activePlanIdx = action.payload
    },

    // settings
    appRightMenu_subMenu_settings_partialReset: (state) => {
      state.subMenu.settings.language = initialState.subMenu.settings.language
      state.subMenu.settings.channelNameInTheList =
        initialState.subMenu.settings.channelNameInTheList
      state.subMenu.settings.resetSettings =
        initialState.subMenu.settings.resetSettings
      state.subMenu.settings.logout = initialState.subMenu.settings.logout
    },
    appRightMenu_subMenu_settings_openSettings: (state) => {
      state.subMenu.settings.isShown = true
      if (!state.subMenu.isOpened) state.subMenu.isOpened = true
    },
    appRightMenu_subMenu_settings_toggleSettingsIsActive: (state) => {
      state.mainMenu.items.settingsIsActive =
        !state.mainMenu.items.settingsIsActive
      state.subMenu.settings.isActive = !state.subMenu.settings.isActive
    },
    appRightMenu_subMenu_settings_click: (state) => {
      if (state.mainMenu.items.settingsIsActive)
        state.mainMenu.items.settingsIsActive = false
      if (!state.subMenu.settings.isActive)
        state.subMenu.settings.isActive = true
    },

    // language
    appRightMenu_subMenu_settings_language_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.subMenu.settings.language.isActive = action.payload
    },
    appRightMenu_subMenu_settings_language_setPosition: (
      state,
      action: PayloadAction<TLanguage>
    ) => {
      state.subMenu.settings.language.position = action.payload
    },
    appRightMenu_subMenu_settings_language_clickHandler: (state) => {
      state.subMenu.settings.language.isActive = true
      state.subMenu.settings.channelNameInTheList.isActive = false
      state.subMenu.settings.resetSettings.isActive = false
      state.subMenu.settings.checkSpeed.isActive = false
      state.subMenu.settings.logout.isActive = false
    },

    // user settings
    appRightMenu_subMenu_settings_channelNameInTheList_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.subMenu.settings.channelNameInTheList.isActive = action.payload
    },
    appRightMenu_subMenu_settings_channelNameInTheList_clickHandler: (
      state
    ) => {
      state.subMenu.settings.channelNameInTheList.isActive = true
      state.subMenu.settings.language.isActive = false
      state.subMenu.settings.resetSettings.isActive = false
      state.subMenu.settings.checkSpeed.isActive = false
      state.subMenu.settings.logout.isActive = false
    },

    // reset settings
    appRightMenu_subMenu_settings_resetSettings_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.subMenu.settings.resetSettings.isActive = action.payload
    },
    appRightMenu_subMenu_settings_resetSettings_clickHandler: (state) => {
      state.subMenu.settings.resetSettings.isActive = true
      state.subMenu.settings.language.isActive = false
      state.subMenu.settings.channelNameInTheList.isActive = false
      state.subMenu.settings.checkSpeed.isActive = false
      state.subMenu.settings.logout.isActive = false
    },

    // logout
    appRightMenu_subMenu_settings_logout_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.subMenu.settings.logout.isActive = action.payload
    },
    appRightMenu_subMenu_settings_logout_clickHandler: (state) => {
      state.subMenu.settings.logout.isActive = true
      state.subMenu.settings.language.isActive = false
      state.subMenu.settings.channelNameInTheList.isActive = false
      state.subMenu.settings.resetSettings.isActive = false
      state.subMenu.settings.checkSpeed.isActive = false
    },

    // devices
    appRightMenu_subMenu_devices_openDevices: (state) => {
      state.subMenu.devices.isShown = true
      if (!state.subMenu.isOpened) state.subMenu.isOpened = true
    },
    appRightMenu_subMenu_devices_toggleDevicesIsActive: (state) => {
      state.mainMenu.items.devicesIsActive =
        !state.mainMenu.items.devicesIsActive
      state.subMenu.devices.isActive = !state.subMenu.devices.isActive
    },
    appRightMenu_subMenu_devices_incrementActiveDeviceIdx: (state) => {
      state.subMenu.devices.activeDeviceIdx++
    },
    appRightMenu_subMenu_devices_decrementActiveDeviceIdx: (state) => {
      state.subMenu.devices.activeDeviceIdx--
    },
    appRightMenu_subMenu_devices_setActiveDeviceIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.subMenu.devices.activeDeviceIdx = action.payload
    },
    appRightMenu_subMenu_devices_deviceClick: (
      state,
      action: PayloadAction<number>
    ) => {
      if (state.mainMenu.items.devicesIsActive)
        state.mainMenu.items.devicesIsActive = false
      if (!state.subMenu.devices.isActive) state.subMenu.devices.isActive = true
      state.subMenu.devices.activeDeviceIdx = action.payload
    },

    // app info
    appRightMenu_subMenu_appInfo_openAppInfo: (state) => {
      state.subMenu.appInfo.isShown = true
      if (!state.subMenu.isOpened) state.subMenu.isOpened = true
    },
    appRightMenu_subMenu_appInfo_toggleAppInfoIsActive: (state) => {
      state.mainMenu.items.appInfoIsActive =
        !state.mainMenu.items.appInfoIsActive
      state.subMenu.appInfo.isActive = !state.subMenu.appInfo.isActive
      state.subMenu.appInfo.privacyPolicy.isActive =
        !state.subMenu.appInfo.privacyPolicy.isActive
    },
    appRightMenu_subMenu_appInfo_privacyPolicy_setIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.subMenu.appInfo.privacyPolicy.isActive = action.payload
    },
    appRightMenu_subMenu_appInfo_privacyPolicy_click: (state) => {
      if (state.mainMenu.items.appInfoIsActive)
        state.mainMenu.items.appInfoIsActive = false
      if (!state.subMenu.appInfo.isActive) state.subMenu.appInfo.isActive = true
      state.subMenu.appInfo.privacyPolicy.isActive = true
    },
  },
})

export const appRightMenuApi = createApi({
  reducerPath: 'appRightMenuApi',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.BASE_CDN_URL,
  }),
  endpoints: (builder) => ({
    check_connection_speed: builder.query<
      ICheckConnectionSpeedResponse,
      { startTime: number }
    >({
      queryFn: async () => {
        const url = `${process.env.BASE_CDN_URL}/monitoring/stream?_=$randnum`
        try {
          const response = await axios.get(url)

          if (response.status !== 200) return { error: response.status }

          const { data } = await axios.get<string>('https://api.my-ip.io/ip')

          return { data: { speed: 20, ip: data } }
        } catch (error) {
          return { error: error }
        }
      },
      keepUnusedDataFor: 0,
    }),
  }),
})

export const appRightMenuReducer = appRightMenuSlice.reducer
export const appRightMenuActions = appRightMenuSlice.actions

export const { useLazyCheck_connection_speedQuery } = appRightMenuApi
