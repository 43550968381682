import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'
import { createApi } from '@reduxjs/toolkit/query/react'

import { axiosBaseQueryTyped, IError } from '@store/axiosBaseQueryTyped'
import { kinozalCommonActions } from '@store/slices/kinozal/kinozal.common.slice'

import {
  IKinozaAsideInitialState,
  ICategoryIconsResponse,
  ICurrentCategoryData,
} from '@assets/types/kinozal/kinozal.aside.types'

const initialState: IKinozaAsideInitialState = {
  asideIsActive: false,
  asideIsCollapsible: true,
  fetchedCategoryName: '',
  fetchedCategoryId: '',
  backToTvIsActive: false,
  recentlyIsActive: false,
  favoritesIsActive: false,
  allIsActive: false,
  main: {
    activeCategoryIdx: 0,
    isActive: false,
  },
}

export const kinozalAsideSlice = createSlice({
  name: 'kinozalAside',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(kinozalCommonActions.kinozalCommon_resetKinozalMode, () => initialState)
  },
  reducers: {
    kinozalAside_reset: (state) => ({
      ...initialState,
      fetchedCategoryName: state.fetchedCategoryName,
      fetchedCategoryId: state.fetchedCategoryId,
    }),
    kinozalAside_toggleAsideCollapsible: (state) => {
      state.asideIsCollapsible = !state.asideIsCollapsible
    },
    kinozalAside_setIsActive: (state, action: PayloadAction<boolean>) => {
      state.asideIsActive = action.payload
      state.asideIsCollapsible = !action.payload
    },

    kinozalAside_setBackToTvIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.backToTvIsActive = action.payload
    },
    kinozalAside_setFavoritesIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.favoritesIsActive = action.payload
    },
    kinozalAside_setRecentlyIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.recentlyIsActive = action.payload
    },
    kinozalAside_main_setMainIsActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.main.isActive = action.payload
    },
    kinozalAside_main_setActiveCategoryIdx: (
      state,
      action: PayloadAction<number>
    ) => {
      state.main.activeCategoryIdx = action.payload
    },
    kinozalAside_main_click: (state, action: PayloadAction<number>) => {
      state.main.activeCategoryIdx = action.payload
      state.main.isActive = true
      state.allIsActive = false
      state.recentlyIsActive = false
      state.favoritesIsActive = false
    },
    kinozalAside_setAllIsActive: (state, action: PayloadAction<boolean>) => {
      state.allIsActive = action.payload
    },
    kinozalAside_allClick: (state) => {
      state.allIsActive = true
      state.main.activeCategoryIdx = 0
      state.main.isActive = false
      state.recentlyIsActive = false
      state.favoritesIsActive = false
    },
    kinozalAside_favoritesClick: (state) => {
      state.favoritesIsActive = true
      state.main.activeCategoryIdx = 0
      state.allIsActive = false
      state.main.isActive = false
      state.recentlyIsActive = false
    },
    kinozalAside_recentlyViewedClick: (state) => {
      state.recentlyIsActive = true
      state.main.activeCategoryIdx = 0
      state.favoritesIsActive = false
      state.allIsActive = false
      state.main.isActive = false
    },
    kinozalAside_main_incrementActiveCategory: (state) => {
      state.main.activeCategoryIdx++
    },
    kinozalAside_main_decrementActiveCategory: (state) => {
      state.main.activeCategoryIdx--
    },
    kinozalAside_setFetchedCategoryData: (
      state,
      action: PayloadAction<ICurrentCategoryData>
    ) => {
      state.fetchedCategoryName = action.payload.name
      state.fetchedCategoryId = action.payload.id
    },
  },
})

export const kinozalAsideApi = createApi({
  reducerPath: 'kinozalAsideApi',
  baseQuery: axiosBaseQueryTyped({
    baseUrl: '',
  }),
  endpoints: (builder) => ({
    // ${baseUrl}/cdn/categories/megogo/index.json
    get_categories_icons: builder.query<ICategoryIconsResponse, void>({
      queryFn: async () => {
        const url = `${process.env.BASE_CDN_URL}/cdn/categories/megogo/index.json`
        try {
          const { data } = await axios.get<ICategoryIconsResponse>(url)

          return { data }
        } catch (error) {
          const { request } = error as AxiosResponse
          const { status, statusText } = request as IError

          return {
            error: {
              status,
              statusText,
            },
          }
        }
      },
    }),
  }),
})

export const { useGet_categories_iconsQuery } = kinozalAsideApi

export const kinozalAsideReducer = kinozalAsideSlice.reducer
export const kinozalAsideActions = kinozalAsideSlice.actions
