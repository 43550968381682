import {
  createListenerMiddleware,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit'

import { AppState } from '@store/store'
import { appActions } from '@store/slices/app.slice'
import {
  kinozalDetailsApi,
  kinozalDetailsActions,
} from '@store/slices/kinozal/kinozal.details'
import { playerActions } from '@store/slices/player.slice'
import { kinozalCommonActions } from '@store/slices/kinozal/kinozal.common.slice'

import {
  IAddChannelsToExtraCategoryPayload,
  ILastWatchedCatchupProgramData,
} from '@assets/types/player.types'

import { getHlsPlayerElement } from '@utils/player/getHlsPlayerElement'
import { getKinozalVideoLink } from '@utils/player/getKinozalVideoLink'
import { TLanguage } from '@assets/types/app.types'

export const listenerMiddleware = createListenerMiddleware()
listenerMiddleware.startListening({
  matcher: isAnyOf(appActions.app_setLastWatchedCatchupProgram),
  effect: (_, listenerApi) => {
    const appState = listenerApi.getState() as AppState
    const currentProgramIdx =
      appState.player.hlsPlayer.currentChannelEpg.currentProgramIdx
    const items = appState.player.hlsPlayer.currentChannelEpg.items
    const selectedCategoryIdx = appState.player.categories.selectedCategoryIdx
    const categories = appState.player.categories.items
    const selectedChannelIdx = appState.player.channels.selectedChannelIdx
    const timeshiftValue = appState.player.hlsPlayer.timeshift.timeshiftValue
    const { logo, id, names, type } =
      categories[selectedCategoryIdx].channels[selectedChannelIdx]
    const {
      start_at,
      stop_at,
      title,
      m3u8,
      id: progId,
    } = items[currentProgramIdx]

    const lastWatchedCatchupProgramData: ILastWatchedCatchupProgramData = {
      id: progId,
      channelLogo: logo,
      channelId: id,
      channelNames: names,
      currentTime: timeshiftValue,
      startAt: start_at,
      stopAt: stop_at,
      programName: title,
      type,
      m3u8,
    }
    listenerApi.dispatch(
      appActions.app_setLastWatchedCatchupProgramData(
        lastWatchedCatchupProgramData
      )
    )
  },
})

listenerMiddleware.startListening({
  matcher: isAnyOf(kinozalDetailsActions.kinozalDetails_setCurrentPlayVideoId), 
  effect: async (action: PayloadAction<{videoId: number | null, setEpisodeIdx?: 'next' | 'prev'}>, listenerApi) => {
    const appState = listenerApi.getState as () => AppState
    const language = appState().app.language
    const filmId = +appState().kinozalDetails.currentFilmId
    const currentEpisodeIdx = appState().kinozalDetails.espisodes.activeIdx
    const currentSeasonIdx = appState().kinozalDetails.seasons.activeIdx
    const recentlyViewed = appState().kinozalCommon.kinozalRecentlyViewedFilms

    const isTrailer = appState().kinozalDetails.isTrailer

    const playlistByIdResult = await listenerApi.dispatch(
      kinozalDetailsApi.endpoints.get_kinozal_playlist_by_id.initiate({
        videoId: action.payload.videoId || 0,
        language,
      })
    )

    if (playlistByIdResult?.data?.status === 'ok' ) {
      const { data } =
        kinozalDetailsApi.endpoints.get_details_by_film_id.select({
          filmId,
          language,
        })(appState())

      if (data?.result && !isTrailer) {
        const videoId = appState().kinozalDetails.currentPlayVideoId

        listenerApi.dispatch(
          kinozalCommonActions.kinozalCommon_setRecentlyViewedFilm({
            title: data.result.title,
            id: data.result.id,
            rating_imdb: data.result.rating_imdb,
            image: data.result.image,
            audioTrackId: null
          })
        )
        if (videoId) {
          listenerApi.dispatch(
            kinozalCommonActions.kinozalCommon_setRecentlyViewedEpisode({
              id: videoId,
            })
          )
        }
        // set current episode and season index to state
        // prev
        if (data?.result && data.result.is_series && action.payload.setEpisodeIdx === 'prev') {
          if (currentEpisodeIdx === 0 && currentSeasonIdx !== 0) {
            listenerApi.dispatch(kinozalDetailsActions.kinozalDetails_seasons_decrementActiveIdx())
            listenerApi.dispatch(kinozalDetailsActions.kinozalDetails_seasons_setSelectedIdx(currentSeasonIdx - 1))
            listenerApi.dispatch(kinozalDetailsActions.kinozalDetails_episodes_setActiveIdx(data.result.season[currentSeasonIdx - 1].total - 1))
            listenerApi.dispatch(
              kinozalDetailsApi.endpoints.get_season_by_id.initiate({
                seasonId: data.result.season[currentSeasonIdx - 1].id,
                language,
              }))
          } else {
            listenerApi.dispatch(kinozalDetailsActions.kinozalDetails_episodes_decrementActiveIdx())
          }
        } 
        // next
        if (data?.result && data.result.is_series && action.payload.setEpisodeIdx === 'next') {
          if (data.result.season[currentSeasonIdx].total === currentEpisodeIdx + 1 && data.result.season.length > currentSeasonIdx + 1) {
            listenerApi.dispatch(kinozalDetailsActions.kinozalDetails_seasons_incrementActiveIdx())
            listenerApi.dispatch(kinozalDetailsActions.kinozalDetails_seasons_setSelectedIdx(currentSeasonIdx + 1))
            listenerApi.dispatch(kinozalDetailsActions.kinozalDetails_episodes_setActiveIdx(0))
            listenerApi.dispatch(
              kinozalDetailsApi.endpoints.get_season_by_id.initiate({
                seasonId: data.result.season[currentSeasonIdx + 1].id,
                language,
              }))
          } else {
            listenerApi.dispatch(kinozalDetailsActions.kinozalDetails_episodes_incrementActiveIdx())
          }
        } 
      }
      // set link to player
      const { link, idx } = getKinozalVideoLink(recentlyViewed, playlistByIdResult.data)

      listenerApi.dispatch(kinozalCommonActions.kinozalCommon_setIsShow(false))
      listenerApi.dispatch(
        kinozalCommonActions.kinozalCommon_setIsKinozalMode(true)
      )
      listenerApi.dispatch(appActions.app_setIsDefaultPlayerMode(false))
      listenerApi.dispatch( playerActions.player_hlsPlayer_timeshift_setLastWatchedTimestamp(null))
      listenerApi.dispatch(
        playerActions.player_hlsPlayer_setSrc({
          link,
          changeDomain: false,
        })
      )
      listenerApi.dispatch(playerActions.player_movieParams_reset(idx))
      listenerApi.dispatch(playerActions.player_hlsPlayer_setVideoDuration(null))
      listenerApi.dispatch(playerActions.player_hlsPlayer_timeshift_setTimeshiftValue(0))
      listenerApi.dispatch(playerActions.player_hlsPlayer_setIsLoadMoreVideo(false))
      listenerApi.dispatch(playerActions.player_elements_resetElementsState())
      listenerApi.dispatch(playerActions.player_elements_showElements())
      listenerApi.dispatch(kinozalDetailsActions.kinozalDetails_removeActiveState())
    }
  },
})

listenerMiddleware.startListening({
  matcher: isAnyOf(appActions.app_setLanguage),
  effect: (action: PayloadAction<TLanguage>, listenerApi) => {
    const appState = listenerApi.getState as () => AppState
    const videoId = appState().kinozalDetails.currentPlayVideoId
    if (videoId) {
      listenerApi.dispatch(
        kinozalDetailsApi.endpoints.get_kinozal_playlist_by_id.initiate({
          videoId: videoId,
          language: action.payload,
        })
      )}
  },
})

listenerMiddleware.startListening({
  matcher: isAnyOf(
    playerActions.player_categories_setChannelItemsToExtraChannelsCategory
  ),
  effect: (
    action: PayloadAction<IAddChannelsToExtraCategoryPayload>,
    listenerApi
  ) => {
    const appState = listenerApi.getState() as AppState
    const category = appState.player.categories.items.find(
      (category) => category.id === action.payload.targetCategoryId
    )

    if (!category?.channels.length)
      return localStorage.removeItem(action.payload.localStorageItemName)

    const channelsIds: string[] = []

    category?.channels.forEach((channel) =>
      channelsIds.push(channel.id.toString())
    )

    localStorage.setItem(
      action.payload.localStorageItemName,
      JSON.stringify(channelsIds)
    )
  },
})

listenerMiddleware.startListening({
  matcher: isAnyOf(playerActions.player_hlsPlayer_rewindToTimestamp),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  effect: (action: PayloadAction<number>, _) => {
    const video = getHlsPlayerElement()
    if (video) video.currentTime = action.payload
  },
})
