import React, { FC } from 'react'
import { clsx } from 'clsx'

import styles from '@components/UIElements/Loader/loader.module.scss'

interface ILoaderProps {
  size: number
  fullScreen?: boolean
  className?: string
  wrapperClassName?: string
}

export const Loader: FC<ILoaderProps> = ({
  size,
  fullScreen,
  className,
  wrapperClassName,
}) => {
  return (
    <div
      className={clsx(
        styles.loader,
        fullScreen && styles.loaderFullscreen,
        wrapperClassName
      )}
      style={{ fontSize: `${size}em` }}
    >
      <span
        className={clsx(!className ? styles.loaderDotDefault : className)}
      />
      <span
        className={clsx(!className ? styles.loaderDotDefault : className)}
      />
      <span
        className={clsx(!className ? styles.loaderDotDefault : className)}
      />
      <span
        className={clsx(!className ? styles.loaderDotDefault : className)}
      />
      <span
        className={clsx(!className ? styles.loaderDotDefault : className)}
      />
    </div>
  )
}
