import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { listenerMiddleware } from '@store/middleware'

import { authApi, authReducer } from '@store/slices/auth.slice'
import { appApi, appReducer } from '@store/slices/app.slice'
import {
  moviesAsideApi,
  moviesAsideReducer,
} from '@store/slices/movies/aside.slice'

import {
  moviesFilmDetailsApi,
  moviesFilmDetailsReducer,
} from '@store/slices/movies/filmDetails.slice'
import { moviesReducer } from '@store/slices/movies/movies.slice'

import {
  moviesFilmListApi,
  moviesFilmListReducer,
} from '@store/slices/movies/filmlist.slice'

import {
  appLeftMenuApi,
  appLeftMenuReducer,
} from '@store/slices/app.left.menu.slice'

import { playerApi, playerReducer } from '@store/slices/player.slice'

import {
  appRightMenuApi,
  appRightMenuReducer,
} from '@store/slices/app.right.menu.slice'

import { overlaysApi, overlaysReducer } from '@store/slices/overlays.slice'

import { dialogsReducer } from '@store/slices/dialogs.slice'

import {
  kinozalDetailsReducer,
  kinozalDetailsApi,
} from '@store/slices/kinozal/kinozal.details'
import {
  kinozalAsideReducer,
  kinozalAsideApi,
} from '@store/slices/kinozal/kinozal.aside.slice'
import {
  kinozalFilmListReducer,
  kinozalFilmListApi,
} from '@store/slices/kinozal/kinozal.filmlist.slice'
import {
  kinozalCommonReducer,
  kinozalCommonApi,
} from '@store/slices/kinozal/kinozal.common.slice'

import {
  MOVIES_RECENTLY_VIEWED_FILMS,
  MOVIES_RECENTLY_VIEWED_EPISODES,
  KINOZAL_RECENTLY_VIEWED_FILMS,
  KINOZAL_RECENTLY_VIEWED_EPISODES,
  KINOZAL_FAVORITES,
  KINOZAL_BLOCKED,
  LAST_WATCHED_CATCHUP_PROGRAM_DATA
} from '@utils/vars'

const moviesRecentlyViewedPersistConfig = {
  key: 'moviesRecentlyViewed',
  whitelist: [MOVIES_RECENTLY_VIEWED_FILMS, MOVIES_RECENTLY_VIEWED_EPISODES],
  storage,
}

const kinozalPersistConfig = {
  key: 'kinozalConfig',
  whitelist: [
    KINOZAL_RECENTLY_VIEWED_FILMS,
    KINOZAL_RECENTLY_VIEWED_EPISODES,
    KINOZAL_FAVORITES,
    KINOZAL_BLOCKED,
  ],
  storage,
}
const appLastWatchedCatchupProgramDataPersistConfig = {
  key: 'lastWatchedCatchupProgramData',
  whitelist: [LAST_WATCHED_CATCHUP_PROGRAM_DATA],
  storage,
}

const combinedReducer = combineReducers({
  auth: authReducer,
  player: playerReducer,
  app: persistReducer(appLastWatchedCatchupProgramDataPersistConfig, appReducer),
  overlays: overlaysReducer,
  dialogs: dialogsReducer,
  appLeftMenu: appLeftMenuReducer,
  appRightMenu: appRightMenuReducer,
  movies: persistReducer(moviesRecentlyViewedPersistConfig, moviesReducer),
  moviesAside: moviesAsideReducer,
  moviesFilmList: moviesFilmListReducer,
  moviesFilmDetails: moviesFilmDetailsReducer,
  kinozalCommon: persistReducer(kinozalPersistConfig, kinozalCommonReducer),
  kinozalDetails: kinozalDetailsReducer,
  kinozalAside: kinozalAsideReducer,
  kinozalFilmList: kinozalFilmListReducer,
  [authApi.reducerPath]: authApi.reducer,
  [playerApi.reducerPath]: playerApi.reducer,
  [appApi.reducerPath]: appApi.reducer,
  [overlaysApi.reducerPath]: overlaysApi.reducer,
  [appLeftMenuApi.reducerPath]: appLeftMenuApi.reducer,
  [appRightMenuApi.reducerPath]: appRightMenuApi.reducer,
  [moviesAsideApi.reducerPath]: moviesAsideApi.reducer,
  [moviesFilmListApi.reducerPath]: moviesFilmListApi.reducer,
  [moviesFilmDetailsApi.reducerPath]: moviesFilmDetailsApi.reducer,
  [kinozalCommonApi.reducerPath]: kinozalCommonApi.reducer,
  [kinozalFilmListApi.reducerPath]: kinozalFilmListApi.reducer,
  [kinozalAsideApi.reducerPath]: kinozalAsideApi.reducer,
  [kinozalDetailsApi.reducerPath]: kinozalDetailsApi.reducer,
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: any) => {
  if (action.type === 'app/app_logout') {
    state = undefined
  }
  return combinedReducer(state, action)
}

export function makeStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(
        authApi.middleware,
        playerApi.middleware,
        appApi.middleware,
        overlaysApi.middleware,
        appLeftMenuApi.middleware,
        appRightMenuApi.middleware,
        moviesAsideApi.middleware,
        moviesFilmListApi.middleware,
        moviesFilmDetailsApi.middleware,
        kinozalCommonApi.middleware,
        kinozalFilmListApi.middleware,
        kinozalAsideApi.middleware,
        kinozalDetailsApi.middleware
      ),
      listenerMiddleware.middleware,
    ],
    devTools: __MODE__ === 'development',
  })
}

export const store = makeStore()
export const persistor = persistStore(store)
export type AppStore = ReturnType<typeof makeStore>
export type AppState = ReturnType<AppStore['getState']>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>
